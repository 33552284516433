import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInWithGoogle, signInWithApple } from '../redux/authSlice';
import './css/Auth.css';
import {faAppleWhole, faChevronRight, faRobot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Apple, Google} from "@mui/icons-material";
import {Button} from "@mui/material";
import Modal from "./Modal";

const Login = ({ showLoginModal, onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const status = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);

    const handleGoogleLogin = () => {
        dispatch(signInWithGoogle()).then(() => {
            onClose(); // Close modal on successful login
        });
    };

    const handleAppleLogin = () => {
        dispatch(signInWithApple()).then(() => {
            onClose(); // Close modal on successful login
        });
    };

    return (
        <Modal show={showLoginModal} onClose={onClose}>
        <div className="login-form-container">
            <h2>Login</h2>
            {/*<form>*/}
            {/*    <div className="input-container">*/}
            {/*        <label>Email</label>*/}
            {/*        <input*/}
            {/*            type="email"*/}
            {/*            value={email}*/}
            {/*            onChange={(e) => setEmail(e.target.value)}*/}
            {/*            required*/}
            {/*        />*/}
            {/*    </div>*/}

            {/*    <button type="submit" className="neon-button">*/}
            {/*        {status === 'loading' ? 'Loading...' : 'Login'}*/}
            {/*    </button>*/}
            {/*</form>*/}
            <div className="oauth-container">
                <Button startIcon={<Google/> } sx={{ minWidth: 240,
                    '&:hover': {
                        background: 'linear-gradient(to right, grey, white)',
                    },
                }} fullWidth variant="contained" onClick={handleGoogleLogin} className="oauth-button">
                      Sign in with Google
                </Button>
            </div>
            <div className="oauth-container">
                <Button startIcon={<Apple/>} sx={{ minWidth: 240,
                    '&:hover': {
                        background: 'linear-gradient(to right, grey, white)',
                    },
                }}fullWidth color="primary" variant="contained" onClick={handleAppleLogin} className="oauth-button">
                      Sign in with Apple
                </Button>
            </div>
            {error && <p className="error">{error}</p>}
        </div>
        </Modal>
    );
};

export default Login;
