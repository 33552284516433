    import React, { useState, useEffect, useRef } from 'react';
    import '../components/css/SplashScreen.css';
    import '../components/css/TopBanner.css';
    import { Container, Row, Col, Dropdown } from 'react-bootstrap';
    import { Link } from 'react-router-dom';
    import { useMediaQuery } from 'react-responsive';
    import { useAuthState } from 'react-firebase-hooks/auth';
    import { auth, signOut } from '../firebase';
    import Modal from './Modal';
    import Login from './Login';
    import {
        FaBars,
        FaTimes,
        FaUserAlt,
        FaUserCircle,
        FaCogs,
        FaWallet,
        FaThLarge,
        FaHome,
        FaBook,
        FaSearch, FaFire
    } from 'react-icons/fa';
    import Wallet from './Wallet';
    import Logout from "./Logout";
    import {useSelector} from "react-redux";

    const TopBanner = () => {
        const [isVisible, setIsVisible] = useState(true);
        const currentUser = useSelector((state) => state.auth.user);
        const [showLoginModal, setShowLoginModal] = useState(false);
        const [showLogoutModal, setShowLogoutModal] = useState(false);

        const [sidePanelOpen, setSidePanelOpen] = useState(false);
        const [dropdownOpen, setDropdownOpen] = useState(false);

        const isMobile = useMediaQuery({ maxWidth: 767 });

        useEffect(() => {
            const handleScroll = () => {
                const currentScrollPos = window.pageYOffset;
                setIsVisible(currentScrollPos < 200);
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);



        const menuItems = [
            { name: 'Home', link: '/', icon: <FaHome /> },
            { name: 'Explore', link: '/explore', icon: <FaSearch /> },
            { name: 'Blogs', link: '/blogs', icon: <FaBook /> },
        ];

        const toggleLoginModal = () => {
            setShowLoginModal(!showLoginModal);
        };

        const toggleLogoutModal = () => {
            setShowLogoutModal(!showLogoutModal);
        };
        const toggleSidePanel = () => {
            setSidePanelOpen(!sidePanelOpen);
        };

        const toggleDropdown = () => {
            setDropdownOpen(prevState => !prevState);
        };

        return (
            <div>
                <div className={`top-banner ${isVisible ? 'visible' : 'hidden'}`}>
                    <Container fluid>
                        <Row>
                            <Col xs>
                                    <h1 className={`banner-text ${isMobile ? 'mobile-text' : ''}`}>

                                        Cheap Chips

                                        <img
                                            src="/singlelogo.png"
                                            alt="Logo"
                                            style={{ width: isMobile ? '15px' : '20px', marginLeft: '5px'}}
                                        />
                                    </h1>
                            </Col>
                            <Col xs="auto" className={`${isMobile ? 'menu-items-mobile mobile' : 'menu-items web'}`}>
                                {!isMobile && (
                                    <>
                                        {menuItems.map((item, index) => (
                                            <Link key={index} to={item.link} className="menu-link">
                                                <div className="icon-text">{item.icon} {item.name}</div>
                                            </Link>
                                        ))}
                                        {currentUser && !isMobile ? (
                                                <Link to="/profile/pricing" className="menu-link login-link"><div className="icon-text"> <FaUserAlt /> {currentUser.full_name.split(" ")[0] || 'User'}</div></Link>
                                            ) : (

                                            <span onClick={toggleLoginModal} className="menu-link login-link">
                                                <div className="icon-text"><FaFire /> Login</div>
                                            </span>
                                        )}
                                        {currentUser && (
                                            <Wallet currentUser={currentUser} />

                                        )}


                                    </>


                                )}
                                {isMobile && (
                                    <FaBars className="menu-icon" onClick={toggleSidePanel} />
                                )}
                            </Col>
                            <Col xs>
                                {isMobile && currentUser && (
                                    <FaUserCircle className="mobile-user-icon" onClick={toggleSidePanel} />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Login showLoginModal={showLoginModal} onClose={toggleLoginModal} />
                <Modal show={showLogoutModal}>
                    <Logout onClose={toggleLogoutModal} />
                </Modal>
                {isMobile && (
                    <div className={`side-panel ${sidePanelOpen ? 'open' : ''} ${currentUser ? 'isLoggedIn' : ''}`} onClick={toggleSidePanel}>
                        <FaTimes className="close-icon" onClick={toggleSidePanel} />
                        {menuItems.map((item, index) => (
                            <Link key={index} to={item.link} className="side-panel-link" >
                                <span>{item.icon} {item.name} </span>
                            </Link>
                        ))}
                        {currentUser ? (
                            <>
                                <Link to="/profile/billing" className="side-panel-link" onClick={toggleSidePanel}><FaUserAlt /> Profile</Link>
                                <span onClick={toggleLogoutModal} className="side-panel-link"><FaUserAlt /> Logout</span>
                            </>
                        ) : (
                            <span onClick={toggleLoginModal} className="side-panel-link">
                                <FaFire /> Login
                            </span>
                        )}
                    </div>
                )}
            </div>
        );
    };

    export default TopBanner;
