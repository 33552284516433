import React, { useState } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {supabase} from "../config/supabaseClient";
import {Cancel, SaveRounded} from "@mui/icons-material";
import {RiReservedFill} from "react-icons/ri";
import {toast, ToastContainer} from "react-toastify";

function Enquiry() {
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({ email: '', industry: '' });


    const handleSubmit =  async (e) => {
        if(!validateForm()) return
        e.preventDefault();
        setLoading(true);

        // Insert demo request into Supabase
        const { data, error } = await supabase
            .from('enterprise_requests')
            .insert([{ email, industry }]);

        setLoading(false);

        if (error) {
            setError('Failed to submit demo request. Please try again.');
            toast.error("Failed to submit demo request. Please try again later.")
        } else {
            // Success: Close the modal and reset form fields
            setShowModal(false);
            setEmail('');
            setIndustry('');
            toast.success("Request has been submitted. We will contact you soon")
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errors = { email: '', industry: '' };

        // Email validation
        if (!email) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors.email = 'Invalid email address';
            isValid = false;
        }

        // Industry validation
        if (!industry) {
            errors.industry = 'Industry is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };
    const handleEditInfoClose = () => {
        setShowModal(!showModal);
    }

    const handleEditInfoChange = (e) => {
        const { name, value } = e.target;
        if(name === 'email'){
            setEmail(value);
        }else{
            setIndustry(value)
        }
    };

    return (
        <>
            <Button
                variant="contained"
                startIcon={<RiReservedFill/>}
                fullWidth
                sx = {{background: 'linear-gradient(270deg, #000000, #434343)'}}
                onClick={() => setShowModal(true)}>Book a demo</Button>
            <Dialog open={showModal} onClose={handleEditInfoClose}>
                <DialogTitle>Edit Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill in the details below to update your account info.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Email"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="email"
                        value={email}
                        onChange={handleEditInfoChange}
                        error={!!errors.industry}
                        helperText={errors.industry}
                    />
                    <TextField
                        margin="dense"
                        label="What industry you are working on? Eg. Medical"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="industry"
                        value={industry}
                        required
                        onChange={handleEditInfoChange}
                        error={!!errors.industry}
                        helperText={errors.industry}
                    />

                </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            startIcon={<Cancel />}
                            fullWidth
                            sx={{background: 'linear-gradient(270deg, #000000, #434343)'}}
                            onClick={handleEditInfoClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveRounded/>}
                            fullWidth
                            sx={{background: 'linear-gradient(270deg, #000000, #434343)'}}
                            onClick={handleSubmit}>Submit</Button>
                    </DialogActions>
            </Dialog>
        </>
    );
}

export default Enquiry;
