// Blog.jsx
import { useEffect, useState } from 'react';
import { supabase } from '../../config/supabaseClient';  // Import the Supabase client
import {useNavigate, useParams} from 'react-router-dom';  // Import the useParams hook
import { Render } from "@measured/puck";
import "@measured/puck/puck.css";
import { config } from "../../config/editor.config";
import './css/Blogs.css';
import {useSelector} from "react-redux";

const Blog = () => {
    const { blogId } = useParams();
    const [blog, setBlog] = useState(null);
    const history = useNavigate();
    const currentUser = useSelector((state) => state.auth.user);


    useEffect(() => {
        const fetchBlog = async () => {
            console.log("fetching -----?>" + blogId)
            const { data, error } = await supabase
                .from('blogs')
                .select('title, author, data')
                .eq('id', blogId)
                .single();

            if (error) {
                console.error('Error fetching blog:', error);
            } else {
                setBlog(data);
            }
        };

        fetchBlog();
    }, [blogId]);

    if (!blog) return <div>Loading...</div>;

    const handleEdit = () => {
        history(`/edit/${blogId}`);
    };

    return (
        <div className="content">
            <Render config={config} data={blog.data} />
            {currentUser && currentUser.email && currentUser.type === "admin" && (<button onClick={handleEdit} className="create-new-button"></button>)}

        </div>
    );
};

export default Blog;
