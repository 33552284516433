import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_APP_API_BASE_URL;

export const fetchChipOffers = createAsyncThunk('chipOffers/fetchChipOffers', async () => {
    const response = await axios.get(`${API_BASE_URL}/chip-offer/getChipOffers`);
    return response.data;
});

// Initialize offers from localStorage
const loadOffersFromLocalStorage = () => {
    try {
        const serializedOffers = localStorage.getItem('offers');
        if (serializedOffers === null) return [];
        return JSON.parse(serializedOffers);
    } catch (err) {
        return [];
    }
};

const chipOffersSlice = createSlice({
    name: 'chipOffers',
    initialState: {
        offers: loadOffersFromLocalStorage(),
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChipOffers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchChipOffers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.offers = action.payload;
                localStorage.setItem('offers', JSON.stringify(action.payload));
            })
            .addCase(fetchChipOffers.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default chipOffersSlice.reducer;
