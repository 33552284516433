import React, {useEffect, useState} from 'react';
import { Box, Card, CardContent, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, FormControlLabel, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Add, Edit, Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { supabase } from "../config/supabaseClient";
import { toast, ToastContainer } from "react-toastify";
import {useParams} from "react-router-dom";

const Settings = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [showApiKeys, setShowApiKeys] = useState(true);
    const [showAccountInfo, setShowAccountInfo] = useState(true);
    const [showNotificationSettings, setShowNotificationSettings] = useState(true);
    const [showDangerZone, setShowDangerZone] = useState(false);
    const [newApiKeyName, setNewApiKeyName] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editInfoOpen, setEditInfoOpen] = useState(false);
    const [editInfo, setEditInfo] = useState({
        firstName: '',
        lastName: '',
        country: '',
        addressLine1: '',
        companyName: '',
    });
    const user = useSelector((state) => state.auth.user);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        fetAPIKeys().then(r => { setApiKeys(r)});
        fetchNotifications().then(r => {setNotifications(r)})
    },[]);

    const fetAPIKeys = async () => {
        try {
            const { data, error } = await supabase
                .from('user_api_keys')
                .select( '*')
                .eq('user_id', user.user_id);

            if (error) throw error;

            // setApiKeys(data);
            return data ?? []

        } catch (error) {
            toast.error('Failed to fetch API Keys');
        }
    }

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const { data, error } = await supabase
                .from('setting_notifications')
                .select('*')

            if (error) throw error;
            setNotifications(data);
            return data ?? []
        } catch (error) {
            toast.error('Failed to fetch notifications', {
                position: 'bottom-right',
                autoClose: 3000,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = async (id, enabled) => {
        try {
            const { data, error } = await supabase
                .from('setting_notifications')
                .update({ enabled: !enabled, updated_at: new Date().toISOString() })
                .eq('id', id);

            if (error) throw error;

            setNotifications(notifications.map(notification =>
                notification.id === id ? { ...notification, enabled: !enabled } : notification
            ));

        } catch (error) {
            toast.error('Failed to update notification settings', {
                position: 'bottom-right',
                autoClose: 3000,
            });
        }
    };
    const handleAddApiKey = async () => {
        const newApiKey = generateApiKey();

        try {
            const { data, error } = await supabase
                .from('user_api_keys')
                .insert([
                    {
                        user_id: user.user_id,
                        name: newApiKey,
                        permissions: 'read',
                        created: new Date().toISOString()
                    }
                ]);

            if (error) throw error;

            setApiKeys([...apiKeys, {
                user_id: user.user_id,
                name: newApiKey,
                permissions: 'read',
                created: new Date().toISOString()
            }]);
            setNewApiKeyName('');
            toast.success('API Key added successfully');
        } catch (error) {
            toast.error('Failed to add API Key');
        }
    };

    const handleDeleteApiKey = async (index) => {
        const apiKeyToDelete = apiKeys[index];

        try {
            const { error } = await supabase
                .from('user_api_keys')
                .delete()
                .eq('user_id', user.user_id)
                .eq('name', apiKeyToDelete.name);

            if (error) throw error;

            setApiKeys(apiKeys.filter((_, i) => i !== index));
            toast.success('API Key deleted successfully');
        } catch (error) {
            toast.error('Failed to delete API Key');
        }
    };

    const handleEditInfoOpen = () => {
        setEditInfoOpen(true);
    };

    const handleEditInfoClose = () => {
        setEditInfoOpen(false);
    };

    const handleEditInfoChange = (e) => {
        const { name, value } = e.target;
        setEditInfo({ ...editInfo, [name]: value });
    };

    const handleEditInfoSubmit = async () => {
        try {
            const { data, error } = await supabase
                .from('user')
                .update({
                    first_name: editInfo.firstName,
                    last_name: editInfo.lastName,
                    country: editInfo.country,
                    address_line1: editInfo.addressLine1,
                    company_name: editInfo.companyName,
                })
                .eq('user_id', user.user_id);

            if (error) throw error;

            toast.success('Info updated successfully');
            handleEditInfoClose();
        } catch (error) {
            toast.error('Failed to update info');
        }
    };
    const generateApiKey = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let apiKey = '';
        for (let i = 0; i < 40; i++) {
            apiKey += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return apiKey;
    };


    return (
        <Box sx={{ padding: '2px',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: isMobile ? '80vw' : '900px',
            gap: '16px',
            minHeight: '100vh',
            alignItems: 'center',}}>
            <Card sx={{width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900,  background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))'}}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Account Info</Typography>
                        <IconButton onClick={() => setShowAccountInfo(!showAccountInfo)}>
                            {showAccountInfo ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                    <Collapse in={showAccountInfo}>
                        <Box sx={{ marginTop: '16px' }}>
                            <Typography variant="body2">Name: {user.full_name}</Typography>
                            <Typography variant="body2">Email: {user.email}</Typography>
                            {/*<Button variant="contained" color="primary" sx={{ marginTop: '16px' }} onClick={handleEditInfoOpen}>Edit Info</Button>*/}
                        </Box>
                    </Collapse>
                </CardContent>
            </Card>

            {/*<Card sx={{ width: isMobile ? '40%' : '90%', minWidth: 275,background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))'}}>*/}
            {/*    <CardContent>*/}
            {/*        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>*/}
            {/*            <Typography variant="h6">API Keys</Typography>*/}
            {/*            <IconButton onClick={() => setShowApiKeys(!showApiKeys)}>*/}
            {/*                {showApiKeys ? <ExpandLess /> : <ExpandMore />}*/}
            {/*            </IconButton>*/}
            {/*        </Box>*/}
            {/*        <Collapse in={showApiKeys}>*/}
            {/*            <Box sx={{ marginTop: '16px' }}>*/}
            {/*                <TextField*/}
            {/*                    label="API Key Name"*/}
            {/*                    value={newApiKeyName}*/}
            {/*                    onChange={(e) => setNewApiKeyName(e.target.value)}*/}
            {/*                    fullWidth*/}
            {/*                />*/}
            {/*                <Button variant="contained" color="primary" onClick={handleAddApiKey} startIcon={<Add />} sx={{ marginTop: '16px' }}>*/}
            {/*                    Add API Key*/}
            {/*                </Button>*/}
            {/*                <TableContainer component={Paper} sx={{ marginTop: '16px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))' }}>*/}
            {/*                    <Table>*/}
            {/*                        <TableHead>*/}
            {/*                            <TableRow>*/}
            {/*                                <TableCell>Name</TableCell>*/}
            {/*                                <TableCell>Permissions</TableCell>*/}
            {/*                                <TableCell>Last Used</TableCell>*/}
            {/*                                <TableCell>Created</TableCell>*/}
            {/*                                <TableCell>Actions</TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                        </TableHead>*/}
            {/*                        <TableBody>*/}
            {/*                            {apiKeys.map((key, index) => (*/}
            {/*                                <TableRow key={index}>*/}
            {/*                                    <TableCell>{key.name}</TableCell>*/}
            {/*                                    <TableCell>{key.permissions}</TableCell>*/}
            {/*                                    <TableCell>{key.lastUsed}</TableCell>*/}
            {/*                                    <TableCell>{key.created}</TableCell>*/}
            {/*                                    <TableCell>*/}
            {/*                                        <IconButton onClick={() => handleDeleteApiKey(index)}>*/}
            {/*                                            <Delete />*/}
            {/*                                        </IconButton>*/}
            {/*                                    </TableCell>*/}
            {/*                                </TableRow>*/}
            {/*                            ))}*/}
            {/*                        </TableBody>*/}
            {/*                    </Table>*/}
            {/*                </TableContainer>*/}
            {/*            </Box>*/}
            {/*        </Collapse>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            <Card sx={{ width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900, background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))'}}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Notification Settings</Typography>
                        <IconButton onClick={() => setShowNotificationSettings(!showNotificationSettings)}>
                            {showNotificationSettings ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                    <Collapse in={showNotificationSettings}>
                        {notifications.map((notification) => (
                            <Box key={notification.id} sx={{ marginTop: '25px' }}>
                                <FormControlLabel control={<Switch
                                    checked={notification.enabled}
                                    onChange={() => handleToggle(notification.id, notification.enabled)}
                                />
                                } label={notification.notification_type}
                                />
                            </Box>
                        ))}
                    </Collapse>
                </CardContent>
            </Card>

            {/*<Card sx={{ width: isMobile ? '40%' : '90%', minWidth: 275, background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))'}}>*/}
            {/*    <CardContent>*/}
            {/*        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>*/}
            {/*            <Typography variant="h6">Danger Zone</Typography>*/}
            {/*            <IconButton onClick={() => setShowDangerZone(!showDangerZone)}>*/}
            {/*                {showDangerZone ? <ExpandLess /> : <ExpandMore />}*/}
            {/*            </IconButton>*/}
            {/*        </Box>*/}
            {/*        <Collapse in={showDangerZone}>*/}
            {/*            <Box sx={{ marginTop: '16px' }}>*/}
            {/*                <Button variant="contained" color="error">Delete Account</Button>*/}
            {/*            </Box>*/}
            {/*        </Collapse>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            <Dialog open={editInfoOpen} onClose={handleEditInfoClose}>
                <DialogTitle>Edit Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill in the details below to update your account info.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="firstName"
                        value={editInfo.firstName}
                        onChange={handleEditInfoChange}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="lastName"
                        value={editInfo.lastName}
                        onChange={handleEditInfoChange}
                    />
                    <TextField
                        margin="dense"
                        label="Country"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="country"
                        value={editInfo.country}
                        onChange={handleEditInfoChange}
                    />
                    <TextField
                        margin="dense"
                        label="Address Line 1"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="addressLine1"
                        value={editInfo.addressLine1}
                        onChange={handleEditInfoChange}
                    />
                    <TextField
                        margin="dense"
                        label="Company Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        name="companyName"
                        value={editInfo.companyName}
                        onChange={handleEditInfoChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditInfoClose}>Cancel</Button>
                    <Button onClick={handleEditInfoSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer position="bottom-right" />
        </Box>
    );
};

export default Settings;
