import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';
import walletReducer from './walletSlice';
import authReducer from './authSlice';
import chipOffersSlice from "./chipOffersSlice";
import appsReducer from './appsSlice'


const store = configureStore({
    reducer: {
        search: searchReducer,
        wallet: walletReducer,
        auth: authReducer,
        chipOffers: chipOffersSlice,
        apps: appsReducer,

    }
});

export default store;
