import React, {useEffect, useState} from 'react';
import './css/Modal.css';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAtom, faCrown, faLock} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../config/supabaseClient";
import {useSelector} from "react-redux";

const AppModal = ({ show, onClose, applications, isMobile, handleAppClick, selectedOffer }) => {

    const user = useSelector((state) => state.auth.user);
    const [accessibleApps, setAccessibleApps] = useState([]);

    useEffect(() => {
        if (!show) {
            return;
        }

        updateApplicationStatus(user, applications).then(r => {});
    }, [user, applications, show]);

    async function updateApplicationStatus(user, applications) {
        if (!user) {
            return;
        }

        const subscribedApps = await fetchSubscribedApps(user.user_id);

        const updatedApplications = applications.map(app => {
            let updatedApp = { ...app };

            const isSubscribed = subscribedApps.some(subscribedApp =>
                subscribedApp.access_status === 'active' && subscribedApp.name === updatedApp.name
            );

            if (isSubscribed) {
                updatedApp.status = 'active';
            }

            return updatedApp;
        });

        setAccessibleApps(updatedApplications);
    }

    // Now, you can use `accessibleApps` wherever needed, e.g., to render the UI

    const fetchSubscribedApps = async (userId) => {
        const { data, error } = await supabase.rpc('get_user_accessible_apps', { userid: userId });

        if (error) {
            console.error('Error fetching subscribed apps:', error);
            return [];
        }

        return data;
    };

    return (
        <div className="modal-overlay">
            <div className="modal-app-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div>
                    <h2>App Store</h2>
                    <Row className="app-row">
                        {accessibleApps.map((app, index) => (
                            <Col
                                key={index}
                                xs={12} sm={isMobile ? 12 : 6} md={4} lg={3}
                                className={app.status === 'active' ? 'app-tile' : 'app-tile-locked'}
                                onClick={() => handleAppClick(app, selectedOffer.id)}
                            >
                                <div className={`app-tile-content ${isMobile ? 'mobile' : ''}`}>
                                    {app.status === 'active' ? (
                                        <FontAwesomeIcon icon={faAtom} size="2x" />
                                    ) : (
                                        <FontAwesomeIcon icon={faLock} size="2x" />
                                    )}
                                    <h3>{app.name}</h3>

                                </div>
                                <div>
                                    {app.status !== 'active' && (
                                        <div className="inactive-tag">
                                            <FontAwesomeIcon icon={faCrown} size="sm" className="crown-icon" />
                                            <span>PRO</span>
                                        </div>
                                    )}
                                </div>

                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default AppModal;
