import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {supabase} from "../config/supabaseClient";

export const fetchApplications = createAsyncThunk(
    'applications/fetchApplications',
    async () => {
        const { data, error } = await supabase.from('apps').select('*');
        if (error) {
            throw new Error(error.message);
        }
        return data;
    }
);

const appsSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApplications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchApplications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.applications = action.payload;
            })
            .addCase(fetchApplications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default appsSlice.reducer;
