import { useEffect, useState } from 'react';
import { supabase } from '../../config/supabaseClient';
import { useNavigate } from 'react-router-dom';
import './css/Blogs.css';
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";

export const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const history = useNavigate();
    const max_length = 200;
    const currentUser = useSelector((state) => state.auth.user);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });


    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await supabase
                .from('blogs')
                .select('id, title, data')
                .order('id', { ascending: false });

            if (error) {
                console.error('Error fetching data:', error);
            } else {
                setBlogs(data);
            }
        };

        fetchData();
    }, []);

    const handleBlogClick = (blogId) => {
        history(`/blog/${blogId}`);
    };

    const handleCreateNew = () => {
        history(`/edit/new`);
    };

    const truncateString = (str, maxLength) => {
        if (str.length <= maxLength) {
            return str;
        }
        return str.substring(0, maxLength) + '...';
    }

    return (
        <div className="blogs-container">
            {currentUser && currentUser.email && currentUser.type === 'admin' && (<button className="create-new-button" onClick={handleCreateNew}></button>)}
            {blogs.map((blog) => {
                const firstTitle = truncateString(
                    Array.isArray(blog?.data?.content) && blog.data.content.length > 0
                        ? blog.data.content[0]?.props?.text
                        : ' ',
                    max_length
                ) || '';

                const firstParagraph = truncateString(
                    Array.isArray(blog?.data?.content) && blog.data.content.length > 0
                        ? blog.data.content[2]?.props?.text
                        : ' ',
                    max_length
                ) || '';

                return (
                    <div className="big-card">
                    <div className="blog-tile" key={blog.id} onClick={() => handleBlogClick(blog.id)}>
                        <div className="blog-image">
                            <img src={blog.image_url || 'blog_logo.png'} alt={firstTitle} />
                        </div>
                        <div className="blog-content">
                            <h2 className="blog-title">{firstTitle}</h2>
                            { !isMobile &&  (<p className="blog-paragraph">{firstParagraph}</p> )}
                        </div>
                    </div>
                    </div>
                );
            })}
        </div>
    );
};