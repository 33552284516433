import React from 'react';
import { Link } from 'react-router-dom';
import './components/css/NotFound.css';

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="central-body">
                <h1 className="error-text">404</h1>
                <p className="error-message">Page Not Found</p>
                <Link to="/" className="home-link">
                    Go to Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
