import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, Box, Button, CssBaseline } from '@mui/material';
import InstancesIcon from '@mui/icons-material/Storage';
import AppsIcon from '@mui/icons-material/Apps';
import BillingIcon from '@mui/icons-material/Receipt';
import PricingIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";
import Pricing from "./Pricing";
import DeployedApps from "./DeployedApps";
import DeployedInstances from "./DeployedInstances";
import { useMediaQuery } from "react-responsive";
import Billing from "./Billing";
import Settings from "./Settings";
import {useParams} from "react-router-dom";
import Logout from "./Logout";
import Modal from "./Modal";

const Profile = () => {
    const [selected, setSelected] = useState('pricing');
    const [subscription, setSubscription] = useState({});
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const user = useSelector((state) => state.auth.user);
    const { page } = useParams();


    useEffect(() => {
        if(page){
            setSelected(page)
        } else {
            setSelected('pricing')
        }
    }, []);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const toggleLogoutModal = () => {
        setShowLogoutModal(!showLogoutModal);
        setSelected('pricing')
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: isMobile ? 60 : 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? 60 : 240,
                        boxSizing: 'border-box',
                        background: 'linear-gradient(45deg, #000000, #aaaaaa)', // Custom background color
                        color: '#FFF', // Custom text color
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: isMobile ? '160px' : '90px',
                        zIndex: 100,
                        overflowY: 'auto', // Enable scrolling within the drawer
                    },
                }}
            >
                <Box>
                    <List>
                        <ListItem sx={{ cursor: 'pointer' }} onClick={() => setSelected('instances')}>
                            <ListItemIcon>
                                <InstancesIcon />
                            </ListItemIcon>
                            {!isMobile && <ListItemText primary="Instances" />}
                        </ListItem>
                        <ListItem sx={{ cursor: 'pointer' }} onClick={() => setSelected('apps')}>
                            <ListItemIcon>
                                <AppsIcon />
                            </ListItemIcon>
                            {!isMobile && <ListItemText primary="Apps" />}
                        </ListItem>
                        {user && (
                            <ListItem sx={{ cursor: 'pointer' }} onClick={() => setSelected('billing')}>
                                <ListItemIcon>
                                    <BillingIcon />
                                </ListItemIcon>
                                {!isMobile && <ListItemText primary="Billing" />}
                            </ListItem>
                        )}
                        <ListItem sx={{ cursor: 'pointer' }} onClick={() => setSelected('pricing')}>
                            <ListItemIcon>
                                <PricingIcon />
                            </ListItemIcon>
                            {!isMobile && <ListItemText primary="Pricing" />}
                        </ListItem>
                        {user && (
                        <ListItem sx={{ cursor: 'pointer' }} onClick={() => setSelected('settings')}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            {!isMobile && <ListItemText primary="Settings" />}
                        </ListItem>
                        )}
                    </List>
                </Box>
                {!isMobile && user && (
                    <Box sx={{ p: 2, cursor: 'pointer' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<LogoutIcon />}
                            onClick={toggleLogoutModal}
                            fullWidth
                            sx = {{background: 'linear-gradient(270deg, #000000, #434343)'}}
                        >
                            Logout
                        </Button>
                    </Box>
                )}
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    mt: 15,
                    backgroundColor: 'linear-gradient(415deg, #45b967, #FFFFFF)',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    alignItems: 'center',
                    gap: '16px',
                }}
            >
                {selected === 'pricing' && <Pricing subscription={subscription} />}
                {selected === 'instances' && <DeployedInstances selected={selected} />}
                {selected === 'apps' && <DeployedApps selected={selected} />}
                {user && selected === 'billing' && <Billing />}
                {user && selected === 'settings' && <Settings />}
            </Box>
            <Modal show={showLogoutModal}>
                <Logout onClose={toggleLogoutModal} />
            </Modal>
        </Box>


    );
};

export default Profile;
