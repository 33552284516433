import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuery, setResults } from '../redux/searchSlice';
import { useMediaQuery } from 'react-responsive';
import './css/Search.css';
import AppModal from "./AppModal";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAtom} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const Search = () => {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.search.query);
    const results = useSelector((state) => state.search.results);
    const offers = useSelector((state) => state.chipOffers.offers);
    const [showModal, setShowModal] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const applications = useSelector((state) => state.apps.applications);
    const searchContainerRef = useRef(null);
    const [selectedOffer,setSelectedOffer] = useState(null);
    const history = useNavigate();

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

    const handleSearch = (event) => {
        const searchQuery = event.target.value.toLowerCase();
        dispatch(setQuery(searchQuery));

        if (searchQuery.trim() === '') {
            dispatch(setResults([]));
            return;
        }

        const filteredResults = offers.filter(offer =>
            offer.description.toLowerCase().includes(searchQuery)
        );
        dispatch(setResults(filteredResults));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };
    const handleAppClick = (app, offerId) => {
        if(app.status === 'active'){
            history(`/deploy/${offerId}/${app.name}`);
        }else {
            history(`/profile/pricing`);
        }

    };

    const handleSearchClick = (chip) => {
        setSelectedOffer(chip);
        setShowModal(true)
    }


    return (
        <div className="search-container" ref={searchContainerRef}>
            <input
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search for GPUs..."
                className="neon-input"
                onFocus={() => setShowResults(true)}
            />
            {showResults && results.length > 0 && (
                <div className="dropdown-results">
                    {results.map((chip, index) => (
                        <a
                        key={index}
                        onClick={()=> handleSearchClick(chip)}
                        rel="noopener noreferrer"
                        className="dropdown-item"
                        >
                        <div className="dropdown-item-content">
                        <img
                        src={`/images/${chip.type}.png`}
                        alt={chip.name}
                        className="dropdown-image"
                        />
                        <span className="dropdown-name">{chip.name}</span>
                        </div>
                        <span className="dropdown-price">${chip.pricing.toFixed(2)}/hr</span>
                        </a>
                        ))}
                </div>
            )}
            {showModal && (
                <AppModal show={showModal} onClose={closeModal} applications={applications} handleAppClick={handleAppClick} selectedOffer={selectedOffer}/>
            )}
        </div>
    );
};

export default Search;