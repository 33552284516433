import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import TopBanner from './components/TopBanner';
import LandingPage from './components/LandingPage';
import SelectOptionsPage from './components/SelectOptionsPage';
import './App.css';
import './theme.css';
import Login from "./components/Login";
import Signup from "./components/Signup";
import Footer from "./components/Footer";
import {Blogs} from "./components/content/Blogs";
import Blog from "./components/content/Blog";
import Editor from "./components/content/Editor";
import Home from "./components/Home";
import AppDeploy from "./components/AppDeploy";
import NotFound from "./NotFound";
import Profile from "./components/Profile";
import AppDetails from "./components/AppDetails";
import {useSelector} from "react-redux";

function App() {
    const user = useSelector((state) => state.auth.user);
    const PrivateRoute = ({ children }) => {
        return user ? children : <Navigate to="/login" />
    }
    return (
        <Router>
            <div className="App">
                <TopBanner />
                <Routes>
                    <Route path="/explore" element={<LandingPage />} />
                    <Route path="/select-options/:id" element={<SelectOptionsPage />} /> {/* Define route for SelectOptionsPage */}
                    <Route path="/login" element={!user ? <Login showLoginModal={true} /> : <Navigate to="/" />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/edit/:blogId" element={<Editor/>} />
                    <Route path="/edit/new" element={<Editor/>} />
                    <Route path="/blog/:blogId" element={<Blog/>} />
                    <Route path="/blogs" element={<Blogs/>} />
                    <Route path="/profile/:page" element={<Profile/>} />
                    <Route path="/" element={<Home/>} />
                    <Route path="/deploy/:offerId/:app" element={<PrivateRoute><AppDeploy/></PrivateRoute>} />
                    <Route path="/deploy/apps/:uuid" element={<PrivateRoute><AppDetails/></PrivateRoute>} />

                    <Route path="*" element={<NotFound/>} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
