// Editor.jsx
import { Puck } from "@measured/puck";
import "@measured/puck/puck.css";
import { config } from "../../config/editor.config";
import { useEffect, useState } from 'react';
import { supabase } from '../../config/supabaseClient';  // Import the Supabase client
import {useParams, useNavigate} from 'react-router-dom';  // Import the useParams and useHistory hooks

const Editor = () => {
    const { blogId } = useParams();  // Get the blog ID from the URL
    const [initialData, setInitialData] = useState([]);
    const [title, setTitle] = useState([]);

    const [author, setAuthor] = useState('admin');  // Default author to 'admin'
    const history = useNavigate()  // Initialize useHistory

    useEffect(() => {
        const fetchBlogData = async () => {
            if (blogId && blogId !== 'new') {
                setInitialData(null)
                const { data: loadedData, error } = await supabase
                    .from('blogs')
                    .select('title, author, data')
                    .eq('id', blogId)
                    .single();

                if (error) {
                    console.error('Error fetching blog data:', error);
                } else {
                    console.log(loadedData.data)
                    setAuthor(loadedData.author);
                    setTitle(loadedData.title)
                    setInitialData(loadedData.data);
                }
            }
        };

        fetchBlogData();
    }, [blogId]);


    const save = async (data) => {
        const title = data?.root?.props?.title || 'Untitled';  // Extract the title from the first element

        if (blogId && blogId !== 'new') {
            const { error } = await supabase
                .from('blogs')
                .update({ title, author, data })
                .eq('id', blogId);

            if (error) {
                console.error('Error updating data:', error);
            } else {
                console.log('Data updated successfully');
                history(`/blog/${blogId}`);  // Redirect to the blog page
            }
        } else {
            const { data: insertedData, error } = await supabase
                .from('blogs')
                .insert([{ title, author, data }])
                .select()
                .single();

            if (error) {
                console.error('Error saving data:', error);
            } else {
                console.log('Data saved successfully');
                history(`/blog/${insertedData.id}`);  // Redirect to the new blog page
            }
        }
    };

    if (initialData === null) {
        return <div>Loading...</div>;
    }


    return (
        <div className="puck-editor">
            <Puck config={config} data={initialData} onPublish={save} headerTitle={title} />
        </div>
    );
};

export default Editor;
