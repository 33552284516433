import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    } from '@mui/material';
import CryptoJS from 'crypto-js';
import valid from 'card-validator';
import { supabase } from "../config/supabaseClient";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {format} from "date-fns";
import handlePayment from "../config/paymentClient";

const Billing = () => {
    const [filter, setFilter] = useState('daily');
    const [spendLimit, setSpendLimit] = useState(40); // initial spend limit
    const [newSpendLimit, setNewSpendLimit] = useState(spendLimit);
    const [open, setOpen] = useState(false); // dialog state
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVC, setCardCVC] = useState('');
    const user = useSelector((state) => state.auth.user);
    const [topupModalOpen, setTopupModalOpen] = useState(false); // State for top-up modal
    const [topupAmount, setTopupAmount] = useState(''); // State for top-up amount
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [billingData, setBillingData] = useState([]);
    const [billingLoading, setBillingLoading] = useState(true);
    const [transactionLoading, setTransactionLoading] = useState(true);

    const [balance, setBalance] = useState(0);
    const [currentGpuSpend, setCurrentGpuSpend] = useState(0);
    const [error, setError] = useState('');

    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
        fetchSpendLimit(user).then(r => { });
        if (user) {
            fetchTransactionDetails().then(r => {
                setTransactionLoading(false);
            });
            fetchBillingData().then(r => {});
        }
    }, [spendLimit]);

    useEffect(() => {
        if (user) {
            fetchUpdatedBalanceAndSpend().then(r => {}); // Initial fetch
            const intervalId = setInterval(fetchUpdatedBalanceAndSpend, 600000); // Poll every hour

            return () => clearInterval(intervalId); // Clean up on unmount
        }
    }, [user]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSpendLimitChange = (event) => {
        setNewSpendLimit(event.target.value);
    };

    const fetchBillingData = async () => {
        try {
            const { data, error } = await supabase
                .from('billing_explorer_data')
                .select('*')
                .eq('user_id', user.user_id);

            if (error) {
                throw error;
            }

            setBillingData(data);
        } catch (error) {
            console.error('Error fetching billing data:', error.message);
        }
    };

    const fetchUpdatedBalanceAndSpend = async () => {
        try {
            // Calculate balance
            const calculatedBalance = await calculateBalance(user.user_id);


            // Fetch GPU spend
            const { data: gpuData, error: gpuError } = await supabase
                .from('deployed_apps')
                .select(`
                deployed_app_id,
                instances:instances (
                    instance_id,
                    cost
                )
            `)
                .eq('user_id', user.user_id);

            if (gpuError) throw gpuError;

            // Calculate total GPU spend across all instances
            const totalGpuSpend = gpuData.reduce((total, app) => {
                return total + app.instances.reduce((appTotal, instance) => {
                    return appTotal + (instance.cost || 0);
                }, 0);
            }, 0);

            setBalance(calculatedBalance);
            setCurrentGpuSpend(totalGpuSpend);
        } catch (error) {
            console.error('Error fetching updated balance and spend:', error.message);
        }
    };

    const calculateBalance = async (userId) => {
        try {
            const { data, error } = await supabase
                .from('user_balance')
                .select('balance')
                .eq('user_id', userId)
                .single();

            if (error) throw error;

            return data.balance || 0;
        } catch (error) {
            console.error('Error calculating balance:', error.message);
            return 0;
        }
    };

    const fetchTransactionDetails = async () => {
        try {
            const { data, error } = await supabase
                .from('user_transactions')
                .select('*')
                .eq('user_id', user.user_id);

            if (error) {
                throw error;
            }

            setRecentTransactions(data);
        } catch (error) {
            console.error('Error fetching transaction details:', error.message);
            return null;
        }finally {
            setTransactionLoading(false)
        }
    };

    const updateOrInsertSpendLimit = async (userId, newSpendLimit) => {
        // First, check if there's an existing entry for the user
        const { data: existingRecord, error: selectError } = await supabase
            .from('user_billings')
            .select('user_id')
            .eq('user_id', userId)
            .single();

        if (selectError && selectError.code !== 'PGRST116') { // PGRST116: no rows found
            console.error('Error checking existing record:', selectError);
            return;
        }

        if (existingRecord) {
            // If there's an existing entry, update it
            const { data: updatedData, error: updateError } = await supabase
                .from('user_billings')
                .update({ spend_limit: newSpendLimit })
                .eq('user_id', userId);

            if (updateError) {
                console.error('Error updating spend limit:', updateError);
            } else {
                console.log('Spend limit updated:', updatedData);
            }
        } else {
            // If there's no existing entry, insert a new one
            const { data: insertedData, error: insertError } = await supabase
                .from('user_billings')
                .insert([{ user_id: userId, spend_limit: newSpendLimit }]);

            if (insertError) {
                console.error('Error inserting spend limit:', insertError);
            } else {
                console.log('Spend limit inserted:', insertedData);
            }
        }
    };
    const handleSaveSpendLimit = async () => {
        try {
            await updateOrInsertSpendLimit(user.user_id, newSpendLimit);
            toast.success('Spend limit updated successfully', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            console.error('Error saving spend limit:', error.message);
            toast.error('Failed to update spend limit', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const fetchSpendLimit = async (user) => {
        try {
            const { data, error } = await supabase
                .from('user_billings')
                .select()
                .eq('user_id', user.user_id);

            if (error) {
                throw error;
            }

            setSpendLimit(data[0]?.spend_limit || spendLimit);
            setNewSpendLimit(data[0]?.spend_limit || spendLimit)

        } catch (error) {
            toast.error('Failed to fetch spend limit', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

        }
    };

    const handleAddCardClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), "MMMM d, yyyy, h:mm a");
    };

    const handleSaveCard = async () => {
        const cardNumberValidation = valid.number(cardNumber);
        const cardExpiryValidation = valid.expirationDate(cardExpiry);
        const cardCVCValidation = valid.cvv(cardCVC);

        if (!cardNumberValidation.isValid) {
            alert('Invalid card number');
            return;
        }

        if (!cardExpiryValidation.isValid) {
            alert('Invalid expiry date');
            return;
        }

        if (!cardCVCValidation.isValid) {
            alert('Invalid CVC');
            return;
        }

        const encryptedCardNumber = CryptoJS.AES.encrypt(cardNumber, 'your-secret-key').toString();
        const encryptedCardExpiry = CryptoJS.AES.encrypt(cardExpiry, 'your-secret-key').toString();
        const encryptedCardCVC = CryptoJS.AES.encrypt(cardCVC, 'your-secret-key').toString();

        try {
            const { data, error } = await supabase
                .from('user_card')
                .insert([
                    {
                        user_id: user.user_id, // replace with actual user ID
                        card_number: encryptedCardNumber,
                        card_expiry: encryptedCardExpiry,
                        card_cvc: encryptedCardCVC,
                    },
                ]);

            if (error) {
                throw error;
            }

            alert('Card added successfully');
            setOpen(false);
        } catch (error) {
            console.error('Error adding card:', error.message);
            alert('Failed to add card');
        }
    };

    // Function to handle top-up button click
    const handleTopUpClick = () => {
        setTopupModalOpen(true);
    };

    // Function to handle top-up modal close
    const handleTopupClose = () => {
        setTopupModalOpen(false);
    };

    // Function to handle top-up submission
    const handleTopupSubmit = () => {
        // Perform top-up logic here
        if (!topupAmount || topupAmount <= 0) {
            setError('Please enter a valid amount');
        } else {
            // Perform the top-up operation
            console.log('Top-up amount:', topupAmount);
            setError('');
            handleTopupClose();
            const checkout = {
                price: topupAmount || 20.99,
            };
            handlePayment(user, "topup", null, "472067", null, checkout).then(r => {})
        }
    };


    return (
        <Box sx={{
            padding: '2px',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: isMobile ? '80vw' : '900px',
            gap: '16px',
            minHeight: '100vh',
            // justifyContent: isMobile ? 'flex-start' : 'center',
            alignItems: 'center',
        }}>
            <Card sx={{ width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))' }}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Balance:
                    </Typography>
                    <Typography variant="h4" component="div">
                        ${balance.toFixed(2)}
                    </Typography><Button
                    className="topup-button"
                    onClick={handleTopUpClick}
                    sx={{
                        transition: 'transform 0.3s ease-in-out', // Transition effect
                        '&:hover': {
                            transform: 'scale(1.5)', // Scale up slightly on hover
                        },

                    }}
                >
                    <AddCircleOutlineIcon  sx ={{color: 'black'}}/>
                </Button>
                    <Typography variant="body2">
                        Spend Limit: ${spendLimit} / hr
                    </Typography>
                    <TextField
                        label="New Spend Limit"
                        type="number"
                        value={newSpendLimit}
                        onChange={handleSpendLimitChange}
                        sx={{ marginTop: '16px' }}
                    />

                    <Typography variant="body2" sx={{ marginTop: '16px' }}>
                        Current GPU Cloud Spend: ${currentGpuSpend.toFixed(3)} / hr
                    </Typography>
                    <Button variant="contained"  onClick={handleSaveSpendLimit} sx={{ marginTop: '16px', background: 'linear-gradient(270deg, #000000, #434343)' }}>
                        Save Spend Limit
                    </Button>
                </CardContent>
            </Card>
            <Dialog open={topupModalOpen} onClose={handleTopupClose}>
                <DialogTitle sx={{ color: "white", background: "linear-gradient(435deg, #333, #144)" }}>
                    Top Up Balance
                </DialogTitle>
                <DialogContent sx={{ background: "linear-gradient(435deg, #333, #144)" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Amount"
                        type="number"
                        fullWidth
                        value={topupAmount}
                        onChange={(e) => setTopupAmount(e.target.value)}
                        InputProps={{
                            style: { color: '#FFF' },
                        }}
                        InputLabelProps={{
                            style: { color: '#FFF' },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#FFF',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FFF',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FFF',
                                },
                            },
                        }}
                        error={!!error}
                        helperText={error}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', background: "linear-gradient(435deg, #333, #144)" }}>
                    <Button variant="contained" onClick={handleTopupClose} sx = {{background: 'linear-gradient(270deg, #000000, #434343)'}}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleTopupSubmit} sx = {{background: 'linear-gradient(270deg, #000000, #434343)'}}>
                        Top Up
                    </Button>
                </DialogActions>
            </Dialog>


            {/*<Card sx={{ width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))' }}>*/}
            {/*    <CardContent>*/}
            {/*        <Typography variant="h6" component="div">*/}
            {/*            Automatic Payment*/}
            {/*        </Typography>*/}
            {/*        <Button variant="contained" color="primary" onClick={handleAddCardClick}>*/}
            {/*            + Add Card*/}
            {/*        </Button>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            {/*<Dialog open={open} onClose={handleClose}>*/}
            {/*    <DialogTitle>Add Card for Automatic Payment</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <TextField*/}
            {/*            autoFocus*/}
            {/*            margin="dense"*/}
            {/*            label="Card Number"*/}
            {/*            type="text"*/}
            {/*            fullWidth*/}
            {/*            variant="outlined"*/}
            {/*            value={cardNumber}*/}
            {/*            onChange={(e) => setCardNumber(e.target.value)}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            margin="dense"*/}
            {/*            label="Expiry Date"*/}
            {/*            type="text"*/}
            {/*            fullWidth*/}
            {/*            variant="outlined"*/}
            {/*            value={cardExpiry}*/}
            {/*            onChange={(e) => setCardExpiry(e.target.value)}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            margin="dense"*/}
            {/*            label="CVC"*/}
            {/*            type="text"*/}
            {/*            fullWidth*/}
            {/*            variant="outlined"*/}
            {/*            value={cardCVC}*/}
            {/*            onChange={(e) => setCardCVC(e.target.value)}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleClose} color="primary">*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*        <Button onClick={handleSaveCard} color="primary">*/}
            {/*            Save*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            <Card sx={{ width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))'}}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Recent Transactions
                    </Typography>
                    <TableContainer component={Paper} sx={{background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))'}}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Invoice</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">Loading...</TableCell>
                                    </TableRow>
                                ) : recentTransactions.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No data available</TableCell>
                                    </TableRow>
                                ) : (
                                recentTransactions.map((row) => (
                                    <TableRow key={row.transaction_id}>
                                        <TableCell>{formatDate(row.transaction_date)}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.amount}</TableCell>
                                        <TableCell>{row.invoice}</TableCell>
                                        <TableCell>{row.status}</TableCell>

                                    </TableRow>
                                ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card sx={{ width: isMobile ? '60%' : '100%', minWidth: isMobile ? 295 : 900, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))' }}>
                <CardContent>
                    <Typography variant="h6" component="div" sx={{ marginBottom: '8px' }}>
                        Billing Explorer
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel>Filter by Time</InputLabel>
                        <Select
                            value={filter}
                            onChange={handleFilterChange}
                            label="Filter by Time"
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="annually">Annually</MenuItem>
                        </Select>
                    </FormControl>

                    <TableContainer component={Paper} sx={{ marginTop: '16px', background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))' }}>
                        <Table aria-label="billing table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>GPU Cloud</TableCell>
                                    <TableCell>CPU Cloud</TableCell>
                                    <TableCell>Endpoint</TableCell>
                                    <TableCell>Serverless</TableCell>
                                    <TableCell>Storage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billingLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">Loading...</TableCell>
                                    </TableRow>
                                ) : billingData.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No data available</TableCell>
                                    </TableRow>
                                ) : (
                                billingData.map((row) => (
                                    <TableRow key={row.date}>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.total}</TableCell>
                                        <TableCell>{row.gpuCloud}</TableCell>
                                        <TableCell>{row.cpuCloud}</TableCell>
                                        <TableCell>{row.endpoint}</TableCell>
                                        <TableCell>{row.serverless}</TableCell>
                                        <TableCell>{row.storage}</TableCell>
                                    </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

        </Box>
    );
};

export default Billing;
