import React, { useState } from 'react';
import Modal from './Modal';
import {Button, Input} from '@mui/material';
import handlePayment from '../config/paymentClient';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {supabase} from "../config/supabaseClient";

const CancelSubscriptionModal = ({ subscription, show, onClose }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [typedName, setTypedName] = useState("");

    const user = useSelector((state) => state.auth.user);

    const handleCancelSubscription = async () => {
        setIsProcessing(true);
        try {
            await handlePayment(user, 'cancel_subscription', null, null, subscription);
            await updateCurrentPROSubscription();
            onClose();
            toast.success('Subscription cancelled', {
                position: "bottom-right",
                autoClose: 3000,
            });
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            toast.error('Failed to cancel subscription. Please try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const updateCurrentPROSubscription = async () => {
        if (user) {
            try {
                const { data, error } = await supabase
                    .from('user_subscriptions')
                    .update({ status: 'cancelled', end_date: new Date().toISOString() })
                    .match({ user_id: user.user_id, plan_id: 2, status: 'active' });

                if (error) {
                    console.error('Error updating subscription:', error);
                    throw error;
                }

            } catch (error) {
                console.error('Failed to update subscription status:', error);
                toast.error('Failed to update subscription status', {
                    position: "bottom-right",
                    autoClose: 3000,
                });
            }
        }
    };


    const handleConfirmCancel = async () => {
        await handleCancelSubscription();
        onClose();
        window.location.reload();
    };

    return (
        <Modal show={show} onClose={onClose}>
            <h2>Cancel Subscription</h2>
            <p>Are you sure you want to cancel your subscription?</p>
            <p>To confirm cancellation, please type your first name below:</p>
            <Input
                type="text"
                variant="contained"
                placeholder="Type your name"
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)}
                sx={{ input: { color: 'white' } }}
            />
            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <Button
                    variant="contained"
                    sx = {{
                        background: 'linear-gradient(270deg, #00aa99, #434343)',
                        '&.Mui-disabled': {
                            background: 'linear-gradient(to right, grey, white)',
                            color: 'rgba(0, 0, 0, 0.7)',
                        },
                        color: 'white',
                        minWidth: '120px'
                    }}
                    onClick={handleConfirmCancel}
                    disabled={isProcessing || (user && typedName.toLowerCase() !== user.full_name.split(' ')[0].toLowerCase())}
                >
                    {isProcessing ? 'Processing...' : 'Confirm'}
                </Button>
                <Button
                    variant="contained"
                    sx = {{
                        background: 'linear-gradient(270deg, #00aa99, #434343)',
                        '&.Mui-disabled': {
                            background: 'linear-gradient(to right, grey, white)',
                            color: 'rgba(0, 0, 0, 0.7)',
                        },
                        color: 'white'
                    }}
                    onClick={onClose}
                >
                    Keep subscribed
                </Button>
            </div>
        </Modal>
    );
};

export default CancelSubscriptionModal;
            
