import React from 'react';
import { useDispatch } from 'react-redux';
import {signOutUser} from '../redux/authSlice';
import './css/Auth.css';
import {Cancel, ThumbUp} from "@mui/icons-material";
import {Button} from "@mui/material";

const DeleteOrStopDeployment = ({ onClose, action, text }) => {

    const handleLogout = () => {
        action()
        onClose();
    };

    return (
        <div className="logout-form-container">
            <h3>{text}</h3>

            <div className="oauth-container">
                <Button startIcon={<ThumbUp/> }  fullWidth sx={{
                    '&:hover': {
                        background: 'linear-gradient(to right, grey, white)',
                    },
                }} variant="contained" onClick={handleLogout} className="oauth-button">
                      Yes
                </Button>
                <Button startIcon={<Cancel/> } fullWidth
                        sx = {{background: 'linear-gradient(270deg, #000000, #434343)',
                            '&:hover': {
                                background: 'linear-gradient(to right, grey, white)',
                            },
                        }}
                        variant="contained" onClick={onClose} className="oauth-button">
                    No
                </Button>
            </div>
        </div>
    );
};

export default DeleteOrStopDeployment;
