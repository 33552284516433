import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './css/SlidingPanel.css';

const SlidingPanel = ({ onFilterChange, onSortChange, isMobile, sortCriteria, resetAll  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('filter');
    const [gpuCount, setGpuCount] = useState(0);
    const [hourlyPrice, setHourlyPrice] = useState(0.1);
    const [ram, setRam] = useState(8);
    const [spot, setSpot] = useState(false);
    const [vcpu, setVcpu] = useState(1);


    const togglePanel = () => {
        setIsOpen(!isOpen);
    };
    const handleGpuCountChange = (event) => {
        const value = event.target.value.split(',').map(Number);
        setGpuCount(value);
        onFilterChange({ gpuCount: value });
    };

    const handleHourlyPriceChange = (event) => {
        const value = event.target.value.split(',').map(Number);
        setHourlyPrice(value);
        onFilterChange({ hourlyPrice: value });
    };

    const handleRamChange = (event) => {
        const value = Number(event.target.value);
        setRam(value);
        onFilterChange({ ram: value });
    };

    const handleSpotChange = (event) => {
        const value = event.target.checked;
        setSpot(value);
        onFilterChange({ spot: value });
    };

    const handleVcpuChange = (event) => {
        const value = Number(event.target.value);
        setVcpu(value);
        onFilterChange({ vcpu: value });
    };
    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleSortClick = (criteria) => {
        onSortChange(criteria);
    };

    const resetFilters = () => {
        setGpuCount(0);
        setHourlyPrice(0.1);
        setRam(8);
        setSpot(false);
        setVcpu(1);

        // Call onFilterChange with default values
        onFilterChange({
            gpuCount: 0,
            hourlyPrice: 0.1,
            ram: 8,
            spot: false,
            vcpu: 1
        });

        // Call onSortChange with null to reset sorting
        onSortChange(null);
    };
    const handleResetAll =() => {
        resetFilters();
        resetAll();
    }

    return (
        <div
            className={`sliding-panel ${isOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {!isOpen && (
                <div className="panel-toggle">
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            )}
            {isOpen && (
                <div className="filter-panel">
                    {isOpen && (
                        <>
                            <div className="tabs">
                                <div
                                    className={`tab ${activeTab === 'filter' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('filter')}
                                >
                                    Filter
                                </div>
                                <div
                                    className={`tab ${activeTab === 'sort' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('sort')}
                                >
                                    Sort By
                                </div>
                            </div>

                            {activeTab === 'filter' && (
                                <>
                                    <h3>Filter (Min)</h3>
                                    <div className="filter-group">
                                        <label>GPU Count</label>
                                        <input
                                            type="range"
                                            min="0"
                                            max="10"
                                            step="1"
                                            value={gpuCount}
                                            onChange={handleGpuCountChange}
                                            className="neon-slider"

                                        />
                                        <span>{gpuCount}</span>
                                    </div>
                                    <div className="filter-group">
                                        <label>Hourly Price ($)</label>
                                        <input
                                            type="range"
                                            min="0.1"
                                            max="100"
                                            step="0.1"
                                            value={hourlyPrice}
                                            onChange={handleHourlyPriceChange}
                                            className="neon-slider"
                                        />
                                        <span>${hourlyPrice}</span>
                                    </div>
                                    <div className="filter-group">
                                        <label>RAM (GB)</label>
                                        <select value={ram} onChange={handleRamChange}>
                                            {[8, 16, 32, 64, 128, 256, 512].map((size) => (
                                                <option key={size} value={size}>
                                                    {size} GB
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="filter-group">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={spot}
                                                onChange={handleSpotChange}
                                            />
                                            Spot Instance
                                        </label>
                                    </div>
                                    <div className="filter-group">
                                        <label>vCPU</label>
                                        <select value={vcpu} onChange={handleVcpuChange}>
                                            {[1, 2, 4, 8, 16, 32, 64, 128].map((count) => (
                                                <option key={count} value={count}>
                                                    {count} vCPU
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )}
                            {activeTab === 'sort' && (
                                <>
                                    <h2>Sort By</h2>
                                    <div className="sort-group">
                                        <button className={`sort-button ${sortCriteria.includes('gpuCount') ? 'active' : ''}`} onClick={() => handleSortClick('gpuCount')}>
                                            GPU Count {sortCriteria.includes('gpuCount') && <span>({sortCriteria.indexOf('gpuCount') + 1})</span>}
                                        </button>
                                        <button className={`sort-button ${sortCriteria.includes('hourlyPrice') ? 'active' : ''}`} onClick={() => handleSortClick('hourlyPrice')}>
                                            Hourly Price {sortCriteria.includes('hourlyPrice') && <span>({sortCriteria.indexOf('hourlyPrice') + 1})</span>}
                                        </button>
                                        <button className={`sort-button ${sortCriteria.includes('ram') ? 'active' : ''}`} onClick={() => handleSortClick('ram')}>
                                            RAM {sortCriteria.includes('ram') && <span>({sortCriteria.indexOf('ram') + 1})</span>}
                                        </button>
                                        <button className={`sort-button ${sortCriteria.includes('vcpu') ? 'active' : ''}`} onClick={() => handleSortClick('vcpu')}>
                                            vCPU {sortCriteria.includes('vcpu') && <span>({sortCriteria.indexOf('vcpu') + 1})</span>}
                                        </button>
                                    </div>
                                </>
                            )}
                            <button onClick={handleResetAll} className="reset-button">
                                Reset All
                            </button>
                        </>

                    )}
                </div>
            )}
        </div>
    );
};

export default SlidingPanel;
