import axios from "axios";
import {toast} from "react-toastify";

const apiKey = process.env.REACT_APP_LEMON_KEY;

const handlePayment = async (user, type, planId, variantId, subscription, checkout) => {
    if (user) {
        if (type === "subscription") {
            checkout.price = 39.99;
            if (subscription.plan_id !== 2) {
                await handlePaymentServiceCall(user, type, planId, variantId, checkout)
            }
        } else if (type === "topup") {
            await handlePaymentServiceCall(user, type, planId, variantId, checkout)
        } else if(type === "cancel_subscription"){
            console.log(subscription)
            await cancelSubscription(subscription.subscription_id);
        }
    } else {
        toast.warn('Please login to activate', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
};

const cancelSubscription = async (subscriptionId) => {
    try {
        console.log(subscriptionId)
        const response = await axios.delete(
            `https://api.lemonsqueezy.com/v1/subscriptions/${subscriptionId}`,
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${apiKey}`
                }
            }
        );

        if (response.status === 200) {
            toast.success('Subscription cancelled successfully', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            throw new Error('Failed to cancel subscription');
        }
    } catch (error) {
        console.error('Error cancelling subscription:', error);
        toast.error('Failed to cancel subscription. Please try again.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
};


const handlePaymentServiceCall = async (user, type, planId, variantId, checkout) => {
    try {
        const response = await axios.post(
            "https://api.lemonsqueezy.com/v1/checkouts",
            {
                data: {
                    type: "checkouts",
                    attributes: {
                        custom_price: checkout.price * 100, // 39.99 USD in cents
                        product_options: {},
                        checkout_options: {
                            embed: false,
                            media: true,
                            logo: true,
                            desc: false,
                            discount: true,
                            dark: true,
                            subscription_preview: true,
                            button_color: "#2DD272"
                        },
                        checkout_data: {
                            custom: {
                                user_id: user.user_id.toString(),
                                type: type ? type.toString() : '',
                                plan_id: planId ? planId.toString() : '1'
                            }
                        },
                        expires_at: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(), // 1 hour from now
                        preview: true
                    },
                    relationships: {
                        store: {
                            data: {
                                type: "stores",
                                id: "112829"
                            }
                        },
                        variant: {
                            data: {
                                type: "variants",
                                id: variantId
                            }
                        }
                    }
                }
            },
            {
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": "application/vnd.api+json",
                    Authorization: `Bearer ${apiKey}`
                }
            }
        );

        if (response.data && response.data.data.attributes.url) {
            // Handle success, perhaps redirect to checkout URL
            window.location.href = response.data.data.attributes.url;
        }
    } catch (error) {
        console.error('Error creating checkout:', error);
        toast.error('Failed to create checkout. Please try again.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
}

export default handlePayment;
