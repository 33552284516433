import React, { useEffect, useState } from 'react';
import './css/AppDeploy.css';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./Login";
import { Button } from "@measured/puck";
import PriceModal from "./PriceModal";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from "../config/supabaseClient";
import Pricing from "./Pricing";
import { v4 as uuidv4 } from 'uuid';
import useAuthReq from "../config/auth.config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
    Card,
    CardContent, Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const API_BASE_URL = process.env.REACT_APP_APP_API_BASE_URL;

const AppDeploy = ({ flagEnabled = true }) => {
    const { offerId, app } = useParams();
    const chipOffers = useSelector((state) => state.chipOffers.offers);
    const [selectedOffer, setSelectedOffer] = useState({});
    const { apiRequest } = useAuthReq();
    const [responseData, setResponseData] = useState(null);
    const history = useNavigate();
    const [appName, setAppName] = useState('');
    const [selectedLLM, setSelectedLLM] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const applications = useSelector((state) => state.apps.applications);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [appId, setAppId] = useState(uuidv4());
    const [expanded, setExpanded] = useState(false);


    const llmOptions = {
        'ChatGPT': ['GPT-2', 'BERT', 'T5', 'RoBERTa', 'XLNet'],
        'ImageGPT': ['flux-dev', 'Stable Diffusion', 'DALL-E'],
    };

    const referral_links = [
        { name: 'vastai', link: 'https://cloud.vast.ai/?ref_id=148431', cloud: 'VAST AI' },
        { name: 'runpod', link: 'https://runpod.io?ref=h76nt4m8', cloud: 'RUN POD' },
        {},
    ];

    useEffect(() => {
        const isAllowed = applications.filter(app => app.status !== 'inactive').find(fetchedApp => fetchedApp.name === app);
        if (user) {
            fetchSubscribedApps(user.user_id).then(result => {
                let isSubscribed = false;

                if (result && !result.some(subscribedApps => subscribedApps.access_status === 'active' && subscribedApps.name === app)) {
                    if (!isAllowed && !isSubscribed) {
                        history('/profile/billing');
                    }
                }
            });
        }



        checkUserSubscription(user).then(r => {});
        const offer = chipOffers.find(offer => offer.id === offerId);
        if (offer) {
            setSelectedOffer(offer);
        }
    }, [chipOffers]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileId = appId;
        const fileExt = file.name.split('.').pop();
        const fileName = `${user.user_id}_${fileId}_${file.name}`;

        try {
            const { data, error } = await supabase.storage
                .from('users_training_files')
                .upload(fileName, file);

            if (error) throw error;

            const {data: insertData, error: insertError } = await supabase
                .from('users_training_files')
                .insert([{
                    deployed_app_id: appId,
                    filename: fileName,
                    original_filename: file.name,
                    file_type: fileExt
                }]).select();


            if (insertError) throw insertError;
            console.log(insertData)

            setUploadedFiles(prev => [...prev, { ...insertData[0], file_type: fileExt, deployed_app_id: appId, filename: fileName, original_filename: file.name }]);
            toast.success('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
        }
    };

    const handleDeleteFile = async (fileId, filename) => {
        try {
            const { error: deleteStorageError } = await supabase.storage
                .from('users_training_files')
                .remove([filename]);

            if (deleteStorageError) throw deleteStorageError;

            const { error: deleteDbError } = await supabase
                .from('users_training_files')
                .delete()
                .eq('id', fileId);

            if (deleteDbError) throw deleteDbError;

            setUploadedFiles(prev => prev.filter(file => file.id !== fileId));
            toast.success('File deleted successfully');
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error('Error deleting file');
        }
    };

    const fetchSubscribedApps = async (userId) => {
        const { data, error } = await supabase.rpc('get_user_accessible_apps', { userid: userId });

        if (error) {
            console.error('Error fetching subscribed apps:', error);
            return [];
        }

        return data;
    };

    const checkUserSubscription = async (user) => {
        const { data, error } = await supabase
            .from('user_subscriptions')
            .select('*')
            .eq('user_id', user.user_id)
            .eq('status', 'active')
            .order('plan_id', { ascending: true });

        if (error) {
            console.error('Error fetching user subscription:', error);
            return {};
        }
        if (data && data[0]?.status === 'active') {
            setSubscription(data[0]);
        }
        return data;
    };

    const handleDeploy = async () => {
        // Validation
        if (!appName) {
            toast.error('App name is required.');
            return;
        }
        if (!selectedLLM) {
            toast.error('Please select a Language Model.');
            return;
        }
        if (!user) {
            setShowLoginModal(true);
        } else {
            const fresh_subscription = await checkUserSubscription(user);
            if (Object.keys(fresh_subscription).length === 0 || !fresh_subscription) {
                setModalVisible(true);
            } else {
                // Proceed with deployment logic
                const endpoint = `${API_BASE_URL}/deploy/create`;
                const method = 'POST';
                const id = uuidv4();
                const data = {
                    id : id,
                    app: app,
                    appName: appName,
                    selectedLLM: selectedLLM,
                    userId: user.user_id,
                    offerDetails: selectedOffer,
                    trainingFiles: uploadedFiles.map(file => file.filename)
                };

                try {
                    const response = await apiRequest(endpoint, method, data);
                    setResponseData(response);
                    toast.success('Deployment successful. Redirecting ...');
                    history(`/deploy/apps/${id}`)
                } catch (error) {
                    console.error('Error during deployment:', error);
                    toast.error('Error during deployment. Please try again.');
                }
            }
        }
    };

    const handleRedirect = () => {
        if (handleGetCloudName() === 'Try again') {
            history('/explore');
            return;
        }
        const refLink = referral_links.find(referral => referral.name === selectedOffer.type);
        window.open(refLink.link, '_blank');
        history('/explore');
    };

    const handleGetCloudName = () => {
        const refLink = referral_links.find(referral => referral.name === selectedOffer.type);
        return refLink ? `Go to ${refLink.cloud}` : 'Try again';
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const closeLoginModal = () => {
        setShowLoginModal(false);
    };

    const renderModalContent = () => {
        return (<Pricing setModalVisible={setModalVisible}></Pricing>);
    };

    return (
        <div className="selected-offer-container">
            <div className="selected-offer-header">
                <h2 className="selected-offer-title">{selectedOffer.name}</h2>
                <span className="selected-offer-price">${selectedOffer.pricing?.toFixed(2)} /hr</span>
            </div>
            <div className="selected-offer-details">
                <span className="selected-offer-detail">{selectedOffer.cpu} CPU cores</span>
                <span className="selected-offer-detail">{(selectedOffer.memory / 1024).toFixed(2)} GB RAM</span>
                {selectedOffer.spot && <span className="selected-offer-detail">Spot Instance</span>}
            </div>
            <input
                type="text"
                className="app-name-input"
                placeholder="Enter your app name"
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
            />
            <div className="llm-tags-container">
                {llmOptions[app].map((llm) => (
                    <span
                        key={llm}
                        className={`llm-tag ${selectedLLM === llm ? 'selected' : ''}`}
                        onClick={() => setSelectedLLM(llm)}
                    >
                        {llm}
                    </span>
                ))}
            </div>

            <Card sx={{ mt: 2, mb: 2, background: 'linear-gradient(to bottom right, rgba(230, 230, 230, 0.8), rgba(0, 0, 0, 0.7))' }}>
                <CardContent>
                    <Typography variant="h10" gutterBottom onClick={toggleExpand} sx={{ textAlign: 'left', cursor: 'pointer', color: 'white' }}>
                        Training data (optional)
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            id="file-upload"
                        />
                        <label htmlFor="file-upload">
                            <Button variant="contained" component="span" sx={{ mb: 2, color: 'black' }}>
                                <AddCircleOutlineIcon sx={{ color: 'black' }} /> Upload file
                            </Button>
                        </label>

                        <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 400, overflow: 'auto',  background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(0, 0, 0, 0.7))' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white', backgroundColor: 'black' }}>File Name</TableCell>
                                        <TableCell sx={{ color: 'white', backgroundColor: 'black' }}>Type</TableCell>
                                        <TableCell sx={{ color: 'white', backgroundColor: 'black' }}>Uploaded At</TableCell>
                                        <TableCell sx={{ color: 'white', backgroundColor: 'black' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadedFiles.map((file) => (
                                        <TableRow key={file.id} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                                            <TableCell sx={{ color: 'white' }}>{file.original_filename}</TableCell>
                                            <TableCell sx={{ color: 'white' }}>{file.file_type}</TableCell>
                                            <TableCell sx={{ color: 'white' }}>{new Date(file.created_at).toLocaleString()}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleDeleteFile(file.id, file.filename)} sx={{ color: 'red' }}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </CardContent>
            </Card>
            {
                flagEnabled ? (
                    <Button className="deploy-button" onClick={handleDeploy}>Deploy</Button>
                ) : (
                    <Button className="deploy-button" onClick={handleRedirect}>{handleGetCloudName()}</Button>
                )
            }

            <PriceModal show={modalVisible} onClose={closeModal} centered>
                {renderModalContent()}
            </PriceModal>
            <Login showLoginModal={showLoginModal} onClose={closeLoginModal} />
            <ToastContainer position="bottom-right" />
        </div>
    );
};

export default AppDeploy;