import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Button, CircularProgress, Box, Switch, FormControlLabel, Dialog,
    DialogTitle, DialogContent, DialogActions, TextField, MenuItem, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Paper, IconButton
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Icon for resuming
import PauseIcon from '@mui/icons-material/Pause';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../config/supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import useAuthReq from "../config/auth.config";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Line} from "react-chartjs-2";
import {format} from "date-fns";
import Modal from "./Modal";
import DeleteOrStopDeployment from "./DeleteOrStopDepoyement";

const API_BASE_URL = process.env.REACT_APP_APP_API_BASE_URL;
const CHECK_INTERVAL = 5000; // Check every 5 seconds
const TIMEOUT_DURATION = 100000; // Timeout after 100 seconds


const AppDetails = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { apiRequest } = useAuthReq();
    const [loading, setLoading] = useState(true);
    const [deploymentSuccess, setDeploymentSuccess] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [stats, setStats] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const [whatsAppEnabled, setWhatsAppEnabled] = useState(false);
    const [telegramEnabled, setTelegramEnabled] = useState(false);
    const [topupModalOpen, setTopupModalOpen] = useState(false);
    const [country, setCountry] = useState('US');
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');
    const [mobileNumbers, setMobileNumbers] = useState([]);
    const [retryVisible, setRetryVisible] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [numberToDelete, setNumberToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showStopModal, setShowStopModal] = useState(false);
    const [isDeploymentStopped, setIsDeploymentStopped] = useState(false);

    const handleTopupClose = () => {
        setTopupModalOpen(false);
    };

    const handleDeleteClick = (number) => {
        setNumberToDelete(number);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const { error } = await supabase
                .from('users_mobile_numbers')
                .delete()
                .eq('id', numberToDelete.id);

            if (error) throw error;

            toast.success('Mobile number deleted successfully!');
            fetchMobileNumbers();
        } catch (error) {
            toast.error('Failed to delete mobile number.');
        }
        setDeleteConfirmOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setNumberToDelete(null);
    };

    const handleTopupOpen = () => {
        setTopupModalOpen(true);
    };

    const handleMobileNumberSave = async () => {
        if (!mobileNumber || mobileNumber.length < 10) {
            setError('Please enter a valid mobile number.');
            return;
        }

        try {
            const { error } = await supabase
                .from('users_mobile_numbers')
                .insert([{ user_id: user.user_id, deployed_app_id:uuid,  mobile_number: mobileNumber, country: country }]);

            if (error) throw error;

            toast.success('Mobile number added successfully!');
            setTopupModalOpen(false);
            fetchMobileNumbers();
        } catch (error) {
            toast.error('Failed to save mobile number.');
        }
    };

    const fetchMobileNumbers = async () => {
        const { data, error } = await supabase
            .from('users_mobile_numbers')
            .select('*')
            .eq('user_id', user.user_id).eq('deployed_app_id', uuid);

        if (error) {
            toast.error('Failed to fetch mobile numbers.');
        } else {
            setMobileNumbers(data);
        }
    };

    useEffect(() => {
        const startTime = Date.now();

        const intervalId = setInterval(() => {
            const elapsedTime = Date.now() - startTime;

            if (elapsedTime > TIMEOUT_DURATION) {
                clearInterval(intervalId);
                setLoading(false);
                toast.error('Deployment timeout. Please try again.');
                return;
            }

            checkDeploymentStatus(intervalId);
        }, CHECK_INTERVAL);

        fetchDeploymentStats();
        fetchMobileNumbers();
    }, []);

    const checkDeploymentStatus = async (intervalId) => {
        const { data, error } = await supabase
            .from('deployed_apps')
            .select('*')
            .eq('deployed_app_id', uuid)
            .eq('user_id', user.user_id)
            .single();

        if (error || !data) {
            toast.error('Error fetching deployment status.');
            clearInterval(intervalId);
            setLoading(false);
            setNotFound(true);
            return;
        }

        if (data.status === 'success') {
            setDeploymentSuccess(true);
            clearInterval(intervalId);
            setLoading(false);
        } else if (data.status === 'failed') {
            toast.error('Deployment failed.');
            clearInterval(intervalId);
            setLoading(false);
            setRetryVisible(true);
        }
    };

    const fetchDeploymentStats = async () => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/deploy/stats/${uuid}`, 'GET');
            setStats(response);
        } catch (error) {
            toast.error('Error fetching deployment stats.');
        }
    };

    const handleStop = async () => {
        try {
            await apiRequest(`${API_BASE_URL}/deploy/stop/${uuid}`, 'POST');
            const { error } = await supabase
                .from('deployed_apps')
                .update({ status: 'paused' })
                .eq('deployed_app_id', uuid);

            if (error) throw error;

            toast.success('Deployment paused successfully.');
            setIsDeploymentStopped(true); // Update the state to indicate deployment is resumed

        } catch (error) {
            toast.error('Error stopping deployment.');
        }
    };

    const handleResume = async () => {
        try {
            await apiRequest(`${API_BASE_URL}/deploy/resume/${uuid}`, 'POST'); // Adjust the API endpoint as needed
            const { error } = await supabase
                .from('deployed_apps')
                .update({ status: 'pending' })
                .eq('deployed_app_id', uuid);

            if (error) throw error;
            toast.success('Deployment resumed successfully.');
            setIsDeploymentStopped(false); // Update the state to indicate deployment is resumed
        } catch (error) {
            toast.error('Error resuming deployment.');
        }
    };

    const toggleDeleteModal = async () => {
        setShowDeleteModal(!showDeleteModal);
    }

    const toggleStopModal = async () => {
        setShowStopModal(!showStopModal);
    }


    const handleDelete = async () => {
        try {
            await apiRequest(`${API_BASE_URL}/deploy/delete/${uuid}`, 'DELETE');
            const { data, error } = await supabase
                .from('deployed_apps') // Replace with your table name
                .delete()
                .eq('deployed_app_id', uuid);

            if (error) {
                console.error('Error deleting record:', error);
            } else {
                console.log('Deleted record:', data);
            }
            toast.success('Deployment deleted successfully.');
            navigate('/profile/apps');
        } catch (error) {
            toast.error('Error deleting deployment.');
        }
    };

    const handleWhatsAppToggle = () => {
        setWhatsAppEnabled(!whatsAppEnabled);
        // Add logic to enable/disable WhatsApp notifications
    };

    const handleTelegramToggle = () => {
        setTelegramEnabled(!telegramEnabled);
        // Add logic to enable/disable Telegram notifications
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), "MMMM d, yyyy, h:mm a");
    };

    const handleRetry = async () => {
        try {
            setLoading(true);
            setRetryVisible(false);
            await apiRequest(`${API_BASE_URL}/deploy/retry/${uuid}`, 'POST');
            toast.success('Retrying deployment...');
        } catch (error) {
            toast.error('Error retrying deployment.');
            setLoading(false);
            setRetryVisible(true);
        }
    };

    const renderActionButton = () => {
        if (isDeploymentStopped) {
            return (
                <Button variant="contained" color="primary" onClick={handleResume}>
                    Resume Deployment
                </Button>
            );
        }
        return (
            <Button variant="contained" color="warning" onClick={toggleStopModal}>
                Stop Deployment
            </Button>
        );
    };


    return (
        <Container maxWidth="md" sx={{ marginTop: 15, padding: 2, background: 'linear-gradient(45deg, #000000, #666666)' }}>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack items vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress
                        size={100} // Adjust the size as needed
                        sx={{ color: 'white' }} // Set the color to black
                    />
                    <Typography
                        variant="h4"
                        sx={{
                            marginTop: 4, // Adjust spacing above the text
                            marginBottom: 0, // Remove bottom margin if unnecessary
                            textAlign: 'center', // Center text horizontally
                        }}
                    >
                        Fetching details!
                    </Typography>
                </Box>

            ) : deploymentSuccess ? (
                <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                    <Typography variant="h4" sx={{ marginBottom: 2 }}>
                        Deployment Successful!
                    </Typography>
                    <Button variant="contained"
                            sx = {{background: 'linear-gradient(270deg, #000000, #434343)',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, grey, white)',
                                    },
                                mb: 2
                                }}
                            onClick={handleTopupOpen}>
                        Add Mobile Numbers
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <FormControlLabel
                            control={<Switch checked={whatsAppEnabled} onChange={() => setWhatsAppEnabled(!whatsAppEnabled)} />}
                            label="WhatsApp"
                        />
                        <FormControlLabel
                            control={<Switch checked={telegramEnabled} onChange={() => setTelegramEnabled(!telegramEnabled)} />}
                            label="Telegram"
                        />
                    </Box>
                </Box>
            ) : notFound ? (
                <Typography variant="h5" color="black" sx={{ textAlign: 'center' }}>
                    No app found
                </Typography>
            ) : (
                <Box sx={{ textAlign: 'center', marginBottom: 4 }}>

                <Typography variant="h5" color="error" sx={{ textAlign: 'center' }}>
                    Deployment Failed
                </Typography>
                {retryVisible && (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRetry}
                    sx={{ borderRadius: '50%', padding: '10px 20px', marginTop: 2 }}
                    >
                    Retry
                    </Button>
                    )}
                </Box>
            )}

            {stats && (
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h6">Deployment Statistics</Typography>
                    <Line
                        data={{
                            labels: stats.labels,
                            datasets: [
                                {
                                    label: 'Usage',
                                    data: stats.usageData,
                                    fill: false,
                                    backgroundColor: 'rgba(75,192,192,0.4)',
                                    borderColor: 'rgba(75,192,192,1)',
                                },
                            ],
                        }}
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                </Box>
            )}



            {/* Modal for adding mobile numbers */}
            <Dialog open={topupModalOpen} onClose={handleTopupClose}>
                <DialogTitle>Add mobile numbers</DialogTitle>
                <DialogContent>
                    <PhoneInput
                        country={country.toLowerCase()}
                        value={mobileNumber}
                        onChange={setMobileNumber}
                        inputStyle={{ width: '100%' }}
                        specialLabel="Mobile Number"
                        placeholder="Enter mobile number"
                    />
                    {error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {error}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTopupClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleMobileNumberSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Table for listing mobile numbers */}
            <TableContainer component={Paper} sx={{
                maxHeight: '400px', // Adjust this value as needed
                overflow: 'auto',
                marginTop: 2,
                background: 'linear-gradient(to bottom right, rgba(4, 230, 184, 0.8), rgba(100, 100, 100, 0.7))' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>Mobile Number</TableCell>
                            <TableCell>Added On</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mobileNumbers.length > 0 ? (
                            mobileNumbers.map((number) => (
                                <TableRow key={number.id}>
                                    <TableCell>{number.country}</TableCell>
                                    <TableCell>{number.mobile_number}</TableCell>
                                    <TableCell>{formatDate(number.created_at)}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDeleteClick(number)}
                                            sx={{
                                                color: 'white',
                                                '&:hover': { color: 'red' },
                                                '&:focus': { color: 'white' }
                                            }}
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>No mobile numbers added yet.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Confirmation Dialog for Deleting Mobile Number */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this mobile number?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4, gap: 2 }}>
                {loading ? (
                    renderActionButton()
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4, gap: 2 }}>
                        {renderActionButton()}

                        <Button
                            variant="contained"
                            color="error"
                            onClick={toggleDeleteModal}
                        >
                            Delete Deployment
                        </Button>
                    </Box>
                )}
            </Box>
            <Modal show={showDeleteModal}>
                <DeleteOrStopDeployment onClose={toggleDeleteModal} action={handleDelete} text="Do you want delete your deployment permanently ?"/>
            </Modal>
            <Modal show={showStopModal}>
                <DeleteOrStopDeployment onClose={toggleStopModal} action={handleStop} text="Do you want pause your deployment ?"/>
            </Modal>

            <ToastContainer position="bottom-right" />
        </Container>
    );
};

export default AppDetails;