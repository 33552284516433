import React from 'react';

const indentationOptions = [
    { label: "None", value: "0px" },
    { label: "Small", value: "20px" },
    { label: "Medium", value: "40px" },
    { label: "Large", value: "60px" },
];

const fontFamilyOptions = [
    { label: "Arial", value: "Arial, sans-serif" },
    { label: "Helvetica", value: "Helvetica, sans-serif" },
    { label: "Times New Roman", value: "Times New Roman, serif" },
    { label: "Courier", value: "Courier, monospace" },
];

const fontSizeOptions = [
    { label: "Small", value: "14px" },
    { label: "Medium", value: "16px" },
    { label: "Large", value: "18px" },
    { label: "Extra Large", value: "24px" },
];

const lineSpacingOptions = [
    { label: "Single", value: "1" },
    { label: "1.15", value: "1.15" },
    { label: "1.5", value: "1.5" },
    { label: "Double", value: "2" },
];

const ListField = ({ onChange, value }) => {
    const addItem = () => {
        onChange([...value, { text: '' }]);
    };

    const updateItem = (index, newText) => {
        const newItems = [...value];
        newItems[index] = { text: newText };
        onChange(newItems);
    };

    const removeItem = (index) => {
        const newItems = value.filter((_, i) => i !== index);
        onChange(newItems);
    };

    return (
        <div>
            {value && value.map((item, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                    <input
                        type="text"
                        value={item.text}
                        onChange={(e) => updateItem(index, e.target.value)}
                        style={{ marginRight: '8px' }}
                    />
                    <button onClick={() => removeItem(index)}>Remove</button>
                </div>
            ))}
            <button onClick={addItem}>Add List Item</button>
        </div>
    );
};
export const config = {

    components: {
        Title: {
            fields: {
                text: { type: "text" },
                color: { type: "text" },
                fontSize: {
                    type: "select",
                    options: [
                        { label: "Small", value: "24px" },
                        { label: "Medium", value: "32px" },
                        { label: "Large", value: "48px" },
                        { label: "Extra Large", value: "64px" },
                    ]
                },
                fontFamily: { type: "select", options: fontFamilyOptions },
                textAlign: {
                    type: "select",
                    options: [
                        { label: "Left", value: "left" },
                        { label: "Center", value: "center" },
                        { label: "Right", value: "right" },
                    ],
                },
            },
            defaultProps: {
                text: "Page Title",
                color: "#FFFFFF",
                fontSize: "48px",
                fontFamily: "Arial, sans-serif",
                textAlign: "center",
            },
            render: ({ text, color, fontSize, fontFamily, textAlign }) => (
                <div style={{
                    padding: "16px",
                    backgroundColor: '#000000',
                }}>
                    <h1 style={{
                        color,
                        fontSize,
                        fontFamily,
                        textAlign,
                        margin: 0,
                    }}>
                        {text}
                    </h1>
                </div>
            ),
        },
        HeadingBlock: {
            fields: {
                children: {
                    type: "text",
                },
                level: {
                    type: "select",
                    options: [
                        { label: "H1", value: "h1" },
                        { label: "H2", value: "h2" },
                        { label: "H3", value: "h3" },
                        { label: "H4", value: "h4" },
                        { label: "H5", value: "h5" },
                        { label: "H6", value: "h6" },
                    ],
                },
            },
            defaultProps: {
                children: "Heading",
                level: "h1",
                color: "#FFFFFF",
            },
            render: ({ children, level }) => {
                const Heading = level;
                return <Heading>{children}</Heading>;
            },
        },
        Paragraph: {
            fields: {
                text: { type: "text" },
                textAlign: {
                    type: "select",
                    options: [
                        { label: "Left", value: "left" },
                        { label: "Center", value: "center" },
                        { label: "Right", value: "right" },
                    ],
                },
                fontFamily: { type: "select", options: fontFamilyOptions },
                fontSize: { type: "select", options: fontSizeOptions },
                color: { type: "text" },
                leftIndentation: { type: "select", options: indentationOptions },
                lineSpacing: { type: "select", options: lineSpacingOptions },
            },
            defaultProps: {
                text: "Paragraph",
                textAlign: "left",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                leftIndentation: "60px",
                lineSpacing: "1.15",
            },
            render: ({ text, textAlign, fontFamily, fontSize, color, leftIndentation, lineSpacing }) => (
                <div style={{
                    padding: 16,
                    backgroundColor: '#000000',
                    paddingLeft: `calc(16px + ${leftIndentation})`,
                }}>
                    <p style={{
                        textAlign,
                        fontFamily,
                        fontSize,
                        color,
                        margin: 0,
                        lineHeight: lineSpacing,
                    }}>
                        {text}
                    </p>
                </div>
            ),
        },

        BulletList: {
            fields: {
                items: { type: "custom", render: ListField },
                fontFamily: { type: "select", options: fontFamilyOptions },
                fontSize: { type: "select", options: fontSizeOptions },
                color: { type: "text" },
                bulletColor: { type: "text" },
                leftIndentation: { type: "select", options: indentationOptions },
                lineSpacing: { type: "select", options: lineSpacingOptions },
            },
            defaultProps: {
                items: [
                    { text: "First item" },
                    { text: "Second item" },
                    { text: "Third item" }
                ],
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                bulletColor: "#00FFCC",
                leftIndentation: "0px",
                lineSpacing: "1.5",
            },
            render: ({ items, fontFamily, fontSize, color, bulletColor, leftIndentation, lineSpacing }) => (
                <div style={{
                    padding: 16,
                    backgroundColor: '#000000',
                    paddingLeft: `calc(16px + ${leftIndentation})`,
                }}>
                    <ul style={{
                        fontFamily,
                        fontSize,
                        color,
                        listStyleType: 'none',
                        paddingLeft: '0',
                        margin: '0',
                    }}>
                        {items.map((item, index) => (
                            <li key={index} style={{
                                marginBottom: `${parseFloat(lineSpacing) * 0.5}em`,
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <span style={{ color: bulletColor, marginRight: '8px' }}>•</span>
                                <span style={{ lineHeight: lineSpacing }}>{item.text}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            ),
        },

        NumberedList: {
            fields: {
                items: { type: "custom", render: ListField },
                fontFamily: { type: "select", options: fontFamilyOptions },
                fontSize: { type: "select", options: fontSizeOptions },
                color: { type: "text" },
                numberColor: { type: "text" },
                leftIndentation: { type: "select", options: indentationOptions },
                lineSpacing: { type: "select", options: lineSpacingOptions },
            },
            defaultProps: {
                items: [
                    { text: "First item" },
                    { text: "Second item" },
                    { text: "Third item" }
                ],
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
                color: "#FFFFFF",
                numberColor: "#00FFCC",
                leftIndentation: "0px",
                lineSpacing: "1.5",
            },
            render: ({ items, fontFamily, fontSize, color, numberColor, leftIndentation, lineSpacing }) => (
                <div style={{
                    padding: 16,
                    backgroundColor: '#000000',
                    paddingLeft: `calc(16px + ${leftIndentation})`,
                }}>
                    <ol style={{
                        fontFamily,
                        fontSize,
                        color,
                        listStyleType: 'none',
                        paddingLeft: '0',
                        margin: '0',
                        counterReset: 'item',
                    }}>
                        {items.map((item, index) => (
                            <li key={index} style={{
                                marginBottom: `${parseFloat(lineSpacing) * 0.5}em`,
                                display: 'flex',
                                alignItems: 'flex-start',
                                counterIncrement: 'item',
                            }}>
                                <span style={{ color: numberColor, marginRight: '8px' }}>
                                    {index + 1}.
                                </span>
                                <span style={{ lineHeight: lineSpacing }}>{item.text}</span>
                            </li>
                        ))}
                    </ol>
                </div>
            ),
        },
        Image: {
            fields: {
                src: { type: "text" },
                alt: { type: "text" },
                width: { type: "number" },
                height: { type: "number" },
            },
            defaultProps: {
                src: "https://via.placeholder.com/400x300",
                alt: "Placeholder image",
                width: 400,
                height: 300,
            },
            render: ({ src, alt, width, height }) => (
                <div style={{ padding: 16, textAlign: 'center' }}>
                    <img
                        src={src}
                        alt={alt}
                        width={width}
                        height={height}
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </div>
            ),
        },
        Button: {
            fields: {
                text: { type: "text" },
                link: { type: "text" },
                variant: {
                    type: "select",
                    options: [
                        { label: "Primary", value: "primary" },
                        { label: "Secondary", value: "secondary" },
                    ],
                },
            },
            defaultProps: {
                text: "Click me",
                link: "#",
                variant: "primary",
            },
            render: ({ text, link, variant }) => (
                <div style={{ padding: 16, textAlign: 'center' }}>
                    <a href={link} style={{
                        padding: '10px 20px',
                        backgroundColor: variant === 'primary' ? '#00ffd1' : '#6c757d',
                        color: 'white',
                        textDecoration: 'none',
                        borderRadius: '5px',
                    }}>
                        {text}
                    </a>
                </div>
            ),
        },
        Spacer: {
            fields: {
                height: { type: "number" },
                backgroundColor: { type: "text" },
            },
            defaultProps: {
                height: 50,
                backgroundColor: '#000000',
            },
            render: ({ height,backgroundColor }) => (
                <div style={{ height: `${height}px`, backgroundColor: `${backgroundColor}` }}></div>
            ),
        },
    },
};