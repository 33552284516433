import React, { useState } from 'react';
import './css/SelectOptionsPage.css';

const SelectOptionsPage = () => {
    const [vram, setVram] = useState('');
    const [diskSize, setDiskSize] = useState('');
    const [repoUrl, setRepoUrl] = useState('');
    const [mode, setMode] = useState('deploy');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    return (
        <div className="select-options-page">
            <h1 className="page-title">Select Options</h1>
            <form className="options-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="vram">Select VRAM</label>
                    <select
                        id="vram"
                        className="neon-dropdown"
                        value={vram}
                        onChange={(e) => setVram(e.target.value)}
                    >
                        <option value="">Select VRAM</option>
                        <option value="8GB">8GB</option>
                        <option value="16GB">16GB</option>
                        <option value="32GB">32GB</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="diskSize">Select Disk Size</label>
                    <select
                        id="diskSize"
                        className="neon-dropdown"
                        value={diskSize}
                        onChange={(e) => setDiskSize(e.target.value)}
                    >
                        <option value="">Select Disk Size</option>
                        <option value="256GB">256GB</option>
                        <option value="512GB">512GB</option>
                        <option value="1TB">1TB</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="repoUrl">GitHub Repo URL</label>
                    <input
                        id="repoUrl"
                        type="text"
                        className="neon-input"
                        value={repoUrl}
                        onChange={(e) => setRepoUrl(e.target.value)}
                        placeholder="Enter GitHub Repo URL"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="mode">Mode</label>
                    <div className="switch">
                        <input
                            type="radio"
                            id="deploy"
                            name="mode"
                            value="deploy"
                            checked={mode === 'deploy'}
                            onChange={() => setMode('deploy')}
                        />
                        <label htmlFor="deploy" className="switch-label">Deploy</label>

                        <input
                            type="radio"
                            id="train"
                            name="mode"
                            value="train"
                            checked={mode === 'train'}
                            onChange={() => setMode('train')}
                        />
                        <label htmlFor="train" className="switch-label">Train</label>
                    </div>
                </div>

                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
};

export default SelectOptionsPage;
