import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {supabase} from "../config/supabaseClient";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import CancelSubscriptionModal from './CancelSubscriptionModal';
import Enquiry from "./Enquiry";
import {useMediaQuery} from "react-responsive";
import handlePayment from "../config/paymentClient";
import {Button} from "@mui/material";
import {EmojiEvents, FreeBreakfast, PropaneSharp} from "@mui/icons-material";
import Login from "./Login";
import Modal from "./Modal";
import './css/Pricing.css'

const Pricing = ( { setModalVisible }) => {

    const user = useSelector((state) => state.auth.user);
    const [freeActivated, setFreeActivated] = useState(false);
    const [proActivated, setProActivated] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);


    const isMobile = useMediaQuery({maxWidth: 767});

    useEffect(() => {
        if(user) {
            checkUserSubscription().then(r => {
            });
        } else {
            // Reset state if the user is logged out
            setFreeActivated(false);
            setProActivated(false);
            setSubscription({});
        }
    }, [user]);

    const checkUserSubscription = async () => {
        if (user) {
            const {data, error} = await supabase
                .from('user_subscriptions')
                .select('*')
                .eq('user_id', user.user_id)
                .eq('status', 'active')
                .order('plan_id', {ascending: false});

            if (error) {
                console.error('Error fetching subscriptions:', error);
            } else if (data && data.length > 0) {
                const highestPlan = data[0];
                setSubscription(highestPlan);
                if (highestPlan.plan_id === 1) {
                    setFreeActivated(true);
                } else if (highestPlan.plan_id === 2) {
                    setProActivated(true);
                    setFreeActivated(true);
                }
            }
        }
    };

    const pollSubscriptionStatus =  async (userId, maxRetries = 12) => {
        let retries = 0;
        const interval = setInterval(async () => {
            retries += 1;
            const {data, error} = await supabase
                .from('user_subscriptions')
                .select('*')
                .eq('user_id', userId)
                .eq('plan_id', 2)
                .eq('status', 'active')
                .order('plan_id', {ascending: false});

            if (error) {
                clearInterval(interval);
                toast.error('Error checking payment status', {
                    position: "bottom-right",
                    autoClose: 3000,
                });
            } else if (data && data.length > 0) {
                setProActivated(true);
                setFreeActivated(true);
                setIsProcessing(false);
                clearInterval(interval);
                toast.success('PRO plan is activated', {
                    position: "bottom-right",
                    autoClose: 3000,
                });
            } else if (retries >= maxRetries) {
                setIsProcessing(false);
                clearInterval(interval);
                toast.error('Payment failed', {
                    position: "bottom-right",
                    autoClose: 3000,
                });
            }
        }, 10000); // polling every 10 seconds
    };


    const toggleCancelModal = () => {
        setShowCancelModal(!showCancelModal);
    };

    

    const handlePROClick = async () => {
        if (user) {
            if (!proActivated) {
                setIsProcessing(true);
                await handlePayment(user, "subscription","2", "468626", subscription, {});
                await pollSubscriptionStatus(user.user_id);
            }
        } else {
            setShowLoginModal(true)
        }
    };

    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };

    const handleActivateFree = async () => {
        if (user) {
            if (!freeActivated) {
                const {data, error} = await supabase
                    .from('user_subscriptions')
                    .insert([
                        {
                            user_id: user.user_id,
                            plan_id: 1,
                            start_date: new Date().toISOString(),
                            end_date: null,
                            status: 'active',
                            created_at: new Date().toISOString(),
                            updated_at: new Date().toISOString(),
                        },
                    ]);
                if (error) {
                    console.error('Error activating free plan:', error);
                } else {
                    setFreeActivated(true);
                    toast.success('Free plan activated', {
                        position: "bottom-right",
                        autoClose: 3000,
                    });
                    if (setModalVisible) {
                        setModalVisible(false)
                    }
                }
            }
        } else {
            setShowLoginModal(true)
        }
    };

    return (
        <div className="pricing-modal">
            <h2>Subscription Plans</h2>
            <Container>
                <Row>
                    <Col md={4} sm={12}>
                        <div className="subscription-card">
                            <div className="subscription-content">
                                <div className="subscription-header">
                                    <h3>Free</h3>
                                </div>
                                <ul>
                                    <li>Pay as you Compute</li>
                                    <li>No auto-switching instance</li>
                                    <li>All cloud access</li>
                                </ul>
                                <div>
                                    <Button variant="contained"
                                            sx = {{background: 'linear-gradient(270deg, #000000, #434343)','&.Mui-disabled': {
                                                    background: 'linear-gradient(to right, grey, white)', // Apply gradient when disabled
                                                    color: 'rgba(0, 0, 0, 0.7)', // Optional: Adjust text color when disabled
                                                },}}
                                            startIcon={<FreeBreakfast/>}
                                            fullWidth
                                            onClick={handleActivateFree} disabled={freeActivated}>
                                        {freeActivated ? 'Activated' : 'Activate Now 0$'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="subscription-card pro">
                            <div className="subscription-tape">
                                <EmojiEvents style={{ marginRight: '8px' }} />
                            </div>
                            <div className="subscription-content">
                                <div className="subscription-header">
                                    <h3>PRO</h3>
                                </div>
                                <ul>
                                    <li>Pay as you Compute</li>
                                    <li>Auto swap for cheap instance</li>
                                    <li>Premium cloud access</li>
                                </ul>
                                <div>
                                    <Button variant="contained"
                                            sx = {{background: 'linear-gradient(270deg, #000000, #434343)' ,
                                                '&.Mui-disabled': {
                                                    background: 'linear-gradient(to right, grey, white)', // Apply gradient when disabled
                                                    color: 'rgba(0, 0, 0, 0.7)', // Optional: Adjust text color when disabled
                                                }
                                            }}
                                            startIcon={<PropaneSharp/>}
                                            fullWidth
                                            onClick={proActivated ? toggleCancelModal : handlePROClick} disabled={isProcessing}>
                                        {proActivated ? 'Cancel Subscription' : isProcessing ? 'Processing Payment...' : (
                                            <span>
                            <span style={{ textDecoration: 'line-through', marginRight: '5px' }}>$59.99</span>
                            $39.99 / Month
                        </span>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="subscription-card">
                            <div className="subscription-content">
                                <div className="subscription-header">
                                    <h3>Enterprise</h3>
                                </div>
                                <ul>
                                    <li>Talk to us</li>
                                    <li>On-prem solutions</li>
                                    <li>24/7 Support</li>
                                </ul>
                                <div><Enquiry>Book a demo</Enquiry></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Login showLoginModal={showLoginModal} onClose={toggleLoginModal} />
            <CancelSubscriptionModal
                subscription={subscription}
                show={showCancelModal}
                onClose={() => setShowCancelModal(false)}
            />
        </div>
    );
};

export default Pricing;