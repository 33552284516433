import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, appleProvider, recaptchaVerifier, signInWithPhoneNumber } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import './css/Auth.css';  // Reuse the same styles or create new ones if needed

const Signup = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const navigate = useNavigate();

    const handleGoogleSignUp = () => {
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                navigate('/');  // Redirect to home page after successful signup
            })
            .catch((error) => {
                console.error("Error during Google sign-up", error);
            });
    };

    const handleAppleSignUp = () => {
        signInWithPopup(auth, appleProvider)
            .then((result) => {
                navigate('/');  // Redirect to home page after successful signup
            })
            .catch((error) => {
                console.error("Error during Apple sign-up", error);
            });
    };

    const handlePhoneSignUp = () => {
        const appVerifier = recaptchaVerifier();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((result) => {
                setConfirmationResult(result);
            })
            .catch((error) => {
                console.error("Error during phone sign-up", error);
            });
    };

    const handleVerifyOtp = () => {
        confirmationResult.confirm(otp)
            .then((result) => {
                navigate('/');  // Redirect to home page after successful signup
            })
            .catch((error) => {
                console.error("Error during OTP verification", error);
            });
    };

    return (
        <div className="auth-container">
            <h2>Sign Up</h2>
            <button onClick={handleGoogleSignUp}>Sign up with Google</button>
            <button onClick={handleAppleSignUp}>Sign up with Apple</button>
            <div>
                <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone number"
                />
                <button onClick={handlePhoneSignUp}>Sign up with Phone</button>
                <div id="recaptcha-container"></div>
            </div>
            {confirmationResult && (
                <div>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="OTP"
                    />
                    <button onClick={handleVerifyOtp}>Verify OTP</button>
                </div>
            )}
            <p>Already have an account? <a href="/login">Login</a></p>
        </div>
    );
};

export default Signup;
