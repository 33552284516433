import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {supabase} from "../config/supabaseClient";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";


const DeployedInstances = ( { selected }) => {

    const [instances, setInstances] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const isMobile = useMediaQuery({ maxWidth: 767 });


    useEffect(() => {
        const getApps = async () => {
            if (selected === 'instances') {
                const deployedInstances = await fetchDeployedInstancesByUser();
                setInstances(deployedInstances);
            }
        };
        getApps().then(r => {});
    }, [selected]);

    const fetchDeployedInstancesByUser = async () => {
        const {data, error} = await supabase
            .from('users')
            .select(`
            user_id,
            email,
            deployed_apps:deployed_apps (
                deployed_app_id,
                deployed_app_name,
                status,
                instances:instances (
                    instance_id,
                    instance_name,
                    status
                )
            )
        `)
            .eq('user_id', user.user_id);

        if (error) {
            console.error('Error fetching deployed instances:', error);
            return [];
        }

    }

        return (
        <Box sx={{
            marginBottom: '40px',
            justifyContent: isMobile ? 'center' : 'flex-start',
        }}>
            <Typography variant="h4">Instances</Typography>
            {instances && instances.length > 0 ? (
                instances.map(app => (
                    <Box key={app.id} sx={{ marginBottom: '16px', padding: '16px', border: '1px solid #ddd' }}>
                        <Typography variant="h6">{app.instances.instance_name}</Typography>
                        <Typography variant="body2">{app.instances.status}</Typography>
                    </Box>
                ))
            ) : (
                <Typography variant="body1">No instances are deployed yet.</Typography>
            )}
        </Box>
    )
}

export default DeployedInstances;

