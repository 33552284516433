import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {supabase} from "../config/supabaseClient";
import {useSelector} from "react-redux";


export const fetchWalletAmount = createAsyncThunk('wallet/fetchWalletAmount', async (userId) => {
    console.log("ggi" )


    const d = calculateBalance(userId);

    console.log("ggi" + d)

    return d;
});

const calculateBalance = async (userId) => {
    try {
        const { data, error } = await supabase
            .from('user_balance')
            .select('balance')
            .eq('user_id', userId)
            .single();

        if (error) throw error;

        return data.balance || 0;
    } catch (error) {
        console.error('Error calculating balance:', error.message);
        return 0;
    }
};

const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        amount: 0,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWalletAmount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWalletAmount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.amount = action.payload;
            })
            .addCase(fetchWalletAmount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default walletSlice.reducer;
